import { SidoName } from "../types";

export const FUNERAL_SIDO: Map<number, SidoName> = new Map([
  [11, SidoName.서울],
  [31, SidoName.경기],
  [21, SidoName.부산],
  [22, SidoName.대구],
  [23, SidoName.인천],
  [24, SidoName.광주],
  [25, SidoName.대전],
  [26, SidoName.울산],
  [29, SidoName.세종],
  [32, SidoName.강원],
  [33, SidoName.충북],
  [34, SidoName.충남],
  [35, SidoName.전북],
  [36, SidoName.전남],
  [37, SidoName.경북],
  [38, SidoName.경남],
  [39, SidoName.제주],
]);

export const FUNERAL_SIDO_LIST = Array.from(FUNERAL_SIDO);
