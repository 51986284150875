import { Box, Stack } from "@chakra-ui/react";
import {
  ColorIcon,
  getCdnImageSrc,
  HStack,
  Image,
  responsive,
  VStack,
} from "goi_common";
import { ReactNode } from "react";

export default function ServiceDataSection() {
  return (
    <VStack
      position="relative"
      css={responsive({ height: { base: "440px", md: "440px" } })}
      padding="48px 0"
      width="100%"
    >
      <Box
        className="white subtitle_21_b"
        css={responsive({ marginBottom: { base: "40px", md: "40px" } })}
      >
        투명함과 정직함으로, 더욱 더 많은
        <br />
        고객분들께 사랑받고 있습니다.
      </Box>

      <Stack
        flexDirection="row"
        alignItems="flex-end"
        css={responsive({
          marginBottom: { base: "24px", md: "24px" },
          gap: { base: "0px", md: "0px" },
        })}
      >
        <ServiceDataItem
          title="맞춤형 장례 견적 수"
          text={
            <>
              <span className="orange650">18</span>만 건
            </>
          }
          hasPlus
        />
        <ServiceDataItem
          title="사전 상담 고객 수"
          text={
            <>
              <span className="orange650">1</span>만 명
            </>
          }
          hasPlus
        />
      </Stack>

      <Stack
        flexDirection="row"
        alignItems="flex-end"
        css={responsive({
          gap: { base: "0px", md: "0px" },
        })}
      >
        <ServiceDataItem
          title="장례 서비스 만족도"
          text={
            <>
              <span className="orange650">4.9</span>점
            </>
          }
        />
        <ServiceDataItem
          title="누적 투자 유치 및 지원금"
          text={
            <>
              <span className="orange650">44</span>억
            </>
          }
        />
      </Stack>

      <Box position="absolute" bottom={"5%"} left={0} margin="0 20px">
        <Image src={getCdnImageSrc("direct/introduce/chart.png")} alt="" />
      </Box>
    </VStack>
  );
}

function ServiceDataItem({
  title,
  text,
  hasPlus,
}: {
  title: string;
  text: ReactNode;
  hasPlus?: boolean;
}) {
  return (
    <VStack width="100%">
      <Box className="gray400 caption_12_sb md_subtitle_18_sb" zIndex={3}>
        {title}
      </Box>
      <HStack>
        <Box
          css={responsive({
            fontSize: { base: "21px" },
            fontWeight: { base: 700 },
            lineHeight: { base: "34px" },
          })}
        >
          {text}
        </Box>
        {hasPlus && (
          <ColorIcon
            css={responsive({
              margin: { base: "6px 0 0 6px", md: "6px 0 0 14px" },
            })}
            name="plus"
            size={8}
            color=""
          />
        )}
      </HStack>
    </VStack>
  );
}
