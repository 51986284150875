import { css } from "@emotion/react";
import { ColorIcon, colors, HStack, VStack } from "goi_common";

interface ReviewCardProps {
  title: string;
  content: string;
  user: {
    picture: string;
    age_and_gender: string;
    nickname: string;
  };
  score: number;
  onClick?: () => void;
  cardWidth?: string;
}

export function ReviewCard({ title, content, user, score, onClick, cardWidth }: ReviewCardProps) {
  return (
    <VStack gap={12} css={reviewCardCss.root} onClick={onClick} width={cardWidth || "303px"}>
      <VStack gap={4}>
        <div className="gray900 pre-wrap" css={reviewCardCss.title}>
          {title}
        </div>
        <div className="caption1 gray700" css={reviewCardCss.content}>
          {content}
        </div>
      </VStack>
      <HStack justifyContent="space-between">
        <HStack gap={6} alignItems="center">
          {user && (
            <>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              {user.picture && <img alt="" src={user.picture} css={reviewCardCss.profile} />}
              <div className="gray400" css={reviewCardCss.name}>
                {user.nickname} | {user.age_and_gender}
              </div>
            </>
          )}
          <HStack alignItems="center" gap={4}>
            <ColorIcon name="rating_star" size={14} color={colors.orange650} />
            <div className="button2 gray600">{score.toFixed(1)}</div>
          </HStack>
        </HStack>
        <HStack className="caption1 gray500" alignItems="center" gap={4}>
          <div>더보기</div>
          <ColorIcon name="arrow_right_small" color={colors.gray500} width={12} height={12} />
        </HStack>
      </HStack>
    </VStack>
  );
}

const reviewCardCss = {
  root: css({
    padding: "22px 20px 24px",
    backgroundColor: colors.white,
    borderRadius: "10px",
    cursor: "pointer",
  }),

  title: css({
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    display: "-webkit-box",
    textOverflow: "ellipsis",
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  }),

  content: css({
    width: "100%",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    overflow: "hidden",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  }),

  profile: css({
    width: "14px",
    height: "14px",
    borderRadius: "50%",
  }),

  name: css({
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 500,
  }),

  line: css({
    width: "1px",
    alignSelf: "stretch",
    background: colors.gray200,
  }),
};
