import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { Typography } from "../constants";
import { colors } from "../styles";
import { CSSPixelValue } from "../types";
import { coerceCssPixelValue } from "../utils";

interface BaseProps {
  typography?: Typography;
  color?: string;
  highlightColor?: string;
  highlightHeight?: CSSPixelValue;
}

type Props = BaseProps & Omit<HTMLAttributes<HTMLDivElement>, keyof BaseProps>;

const Highlight = ({
  typography = Typography.subtitle2_b,
  color = colors.gray900,
  highlightColor = colors.warmGray300,
  highlightHeight = 12,
  children,
  ...rest
}: Props) => {
  return (
    <HighlightRoot {...rest}>
      <HighlightBackground
        highlightColor={highlightColor}
        highlightHeight={highlightHeight}
      />
      <HighlightText className={typography}>{children}</HighlightText>
    </HighlightRoot>
  );
};

const HighlightRoot = styled.h4`
  width: fit-content;
  position: relative;
`;

const HighlightText = styled.span`
  position: relative;
`;

const HighlightBackground = styled.div<Props>`
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  width: 100%;
  height: ${(props) => coerceCssPixelValue(props.highlightHeight)};
  background: ${(props) => props.highlightColor};
`;

export default Highlight;
