import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { flexCentering, Button } from "goi_common";

interface SlidingMenuProps {
  items: string[];
  current: number;
  setCurrent: (current: number) => void;
}

function Menu({ items, current, setCurrent }: SlidingMenuProps) {
  const length = items.length;

  return (
    <SlidingMenuRoot current={current} length={length}>
      <SlidingMenuItems>
        {items.map((i, index) => (
          <SlidingMenuItem
            key={i}
            length={length}
            isActive={current === index}
            onClick={() => setCurrent(index)}
            className="subtitle2_b"
            // event={`${i} 클릭` as OutdatedGTMEventName}
          >
            {i}
          </SlidingMenuItem>
        ))}
      </SlidingMenuItems>
    </SlidingMenuRoot>
  );
}

const SlidingMenuRoot = styled.div<{ current: number; length: number }>`
  padding: 0;
  width: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SlidingMenuItems = styled.div`
  display: flex;
  width: 100%;
`;

const SlidingMenuItem = styled(Button)<{ length: number; isActive: boolean }>`
  ${flexCentering("row")}
  width: auto;
  white-space: nowrap;
  margin-right: 16px;

  ${({ isActive }) =>
    isActive
      ? css`
          font-weight: bold;
          color: var(--black);
        `
      : css`
          color: var(--gray500);
        `}
  transition: 0.2s;
`;

export default Menu;
