import { lastCheckFunnels } from "../constants";
import { CompositeEventTypes } from "../types";
import { NewGTMCustomEvent, TriggerHackleCustomEvent } from "./customEvent";

export type CompositeEventProps = {
  funnel?: { id: CompositeEventTypes; step: number };
};

export const compositeEvent = (event: CompositeEventProps) => {
  if (event.funnel) {
    FunnelEvent(event.funnel);
  }
};

const FunnelEvent = ({
  id,
  step,
}: {
  id: CompositeEventTypes;
  step: number;
}) => {
  const localStorageEvents = localStorage.getItem(id);

  const checkEvents = lastCheckFunnels[id];
  const arrayIndex = step - 1;
  const eventStep = checkEvents[arrayIndex];
  const funnelLength = checkEvents.length;

  if (localStorageEvents) {
    const existEventFunnels = JSON.parse(localStorageEvents);

    if (existEventFunnels[arrayIndex - 1]["pass"]) {
      existEventFunnels[arrayIndex].pass = true;
      localStorage.setItem(id, JSON.stringify(existEventFunnels));

      if (funnelLength === step) {
        NewGTMCustomEvent({ action: id });
        TriggerHackleCustomEvent({ action: id });
        localStorage.removeItem(id);
      }
    }
  } else {
    if (arrayIndex === 0) {
      eventStep.pass = true;
      localStorage.setItem(id, JSON.stringify(checkEvents));
    }
  }
};
