import { useEffect } from "react";

declare global {
  interface Window {
    kakao: any;
  }
}

export function useMap(
  latitude: number,
  longitude: number,
  disabled = false,
  adjustable = true
) {
  const id = "map";

  useEffect(() => {
    if (disabled) return;

    const mapScript = document.createElement("script");

    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.NEXT_PUBLIC_KAKAOMAP_APPKEY}&autoload=false`;

    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      window.kakao.maps.load(() => {
        const container = document.getElementById(id);
        const options = {
          center: new window.kakao.maps.LatLng(latitude, longitude),
          draggable: adjustable,
          disableDoubleClick: !adjustable,
        };
        const map = new window.kakao.maps.Map(container, options);
        const markerPosition = new window.kakao.maps.LatLng(
          latitude,
          longitude
        );
        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });
        marker.setMap(map);
      });
    };
    mapScript.addEventListener("load", onLoadKakaoMap);

    return () => mapScript.removeEventListener("load", onLoadKakaoMap);
  }, [disabled, latitude, longitude]);

  return id;
}
