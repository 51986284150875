import { css } from "@emotion/react";
import { colors } from "../styles";
import { HStack } from "./HStack";
import { VStack } from "./VStack";
import { ColorIcon } from "./Icons";
import React, { ComponentProps, ReactNode } from "react";
import { Radio } from "./Radio";
import OptionButton from "./OptionButton";

interface FieldProps {
  title?: string;
  error?: {
    show: boolean;
    text: string;
  };
}

export function Field({ title }: FieldProps) {
  return <FieldTemplate title={title} />;
}

Field.Input = FieldInput;
Field.InputButton = FieldInputButton;
Field.Radio = FieldRadio;
Field.OutlinedRadioBox = OutlinedRadioBox;
Field.Textarea = FieldTextarea;
Field.ButtonGroups = FieldButtonGroups;

interface FieldTemplateProps extends FieldProps {
  gap?: number;
  children?: ReactNode;
}

function FieldTemplate({ gap, title, error, children }: FieldTemplateProps) {
  return (
    <VStack gap={12}>
      <VStack gap={gap}>
        {title && (
          <div
            className="subtitle2_sb"
            css={css`
              white-space: pre-line;
            `}
          >
            {title}
          </div>
        )}
        {children && <div>{children}</div>}
      </VStack>
      {error?.show && (
        <div
          className="body1"
          css={css`
            color: ${colors.danger};
          `}
        >
          {error.text}
        </div>
      )}
    </VStack>
  );
}

interface FieldInputProps
  extends FieldProps,
    Omit<ComponentProps<"input">, "title" | "onChange" | "value"> {
  onChange: (value: string) => void;
  value: string;
  variant?: string;
  suffix?: string;
}

function FieldInput({
  variant,
  title,
  error,
  onChange,
  value,
  suffix,
  ...inputProps
}: FieldInputProps) {
  return (
    <FieldTemplate gap={12} title={title} error={error}>
      <div
        css={css`
          position: relative;
        `}
      >
        <input
          {...inputProps}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          className="body2"
          css={css`
            ${variant === "outlined" ? outlinedCss : inputCss}
          `}
        />
        {value.length > 0 && !suffix && (
          <div
            onClick={() => onChange?.("")}
            css={css`
              position: absolute;
              right: 8px;
              top: 10px;
              bottom: 10px;
            `}
          >
            <ColorIcon name="clear" size={30} color={""} />
          </div>
        )}
        {suffix && (
          <div
            css={css`
              position: absolute;
              right: 12px;
              top: 10px;
              bottom: 10px;
            `}
            className="body_16_m gray900"
          >
            만원
          </div>
        )}
      </div>
    </FieldTemplate>
  );
}

interface FieldInputButtonProps extends FieldProps {
  value: string;
  onClick: () => void;
  placeholder?: string;
}

function FieldInputButton({
  value,
  onClick,
  placeholder,
  title,
  error,
}: FieldInputButtonProps) {
  return (
    <FieldTemplate gap={12} title={title} error={error}>
      <button
        placeholder={placeholder}
        className="body2"
        onClick={onClick}
        css={css`
          ${inputCss}
          ${value ? inputFillCss : inputEmptyCss}
        `}
      >
        {value || placeholder}
      </button>
    </FieldTemplate>
  );
}

interface FieldTextareaProps
  extends FieldProps,
    Omit<ComponentProps<"textarea">, "title"> {
  hint?: string;
}

function FieldTextarea({
  hint,
  title,
  error,
  ...textareaProps
}: FieldTextareaProps) {
  return (
    <FieldTemplate gap={12} title={title} error={error}>
      <VStack gap={12}>
        <textarea
          {...textareaProps}
          className="body3_r"
          css={css`
            width: 100%;
            padding: 16px 20px;
            outline: none;
            background: ${colors.gray50};
            border-radius: 8px;
            color: black;
            min-height: 100px;
            resize: none;
            ::placeholder {
              color: ${colors.gray500};
            }
          `}
        />
        <HStack justifyContent="flex-end" className="caption1 gray600">
          {hint}
        </HStack>
      </VStack>
    </FieldTemplate>
  );
}

interface FieldRadioProps extends FieldProps {
  items: string[];
  value: string | undefined;
  onChange: (value: string) => void;
  radioWidth: string;
  flexDirection?: "row" | "column";
  className?: string;
  alignItem?: string;
}

function FieldRadio({
  items,
  value,
  onChange,
  title,
  error,
  radioWidth,
  className,
  alignItem,
  flexDirection = "row",
}: FieldRadioProps) {
  return (
    <FieldTemplate gap={20} title={title} error={error}>
      <div
        css={{
          display: "flex",
          flexDirection: flexDirection,
          flexWrap: "wrap",
          gap: 16,
        }}
      >
        {items.map((item, index) => (
          <HStack
            width={radioWidth}
            key={index}
            css={{
              borderRadius: "5px",
            }}
            alignItems={alignItem}
          >
            <div
              css={{
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => {
                onChange(item);
              }}
            >
              <Radio
                index={`${title}-${index}`}
                className={className}
                checked={value === item}
              />
              <div
                className={className}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </div>
          </HStack>
        ))}
      </div>
    </FieldTemplate>
  );
}

function OutlinedRadioBox(props: FieldRadioProps) {
  const {
    items,
    value,
    onChange,
    title,
    error,
    radioWidth,
    className,
    alignItem,
    flexDirection = "row",
  } = props;

  return (
    <FieldTemplate gap={20} title={title} error={error}>
      <div
        css={{
          display: "flex",
          flexDirection: flexDirection,
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        {items.map((item, index) => (
          <HStack
            width={radioWidth}
            key={index}
            css={{
              background: "white",
              border: value === item ? `1px solid ${colors.orange650}` : "",
              borderRadius: "5px",
              padding: "10px 12px",
              cursor: "pointer",
            }}
            alignItems={alignItem}
            onClick={() => {
              onChange(item);
            }}
          >
            <Radio
              index={`${title}-${index}`}
              className={className}
              checked={value === item}
            />
            <div
              className={className}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </HStack>
        ))}
      </div>
    </FieldTemplate>
  );
}

interface FieldButtonGroupsProps extends FieldProps {
  items: string[];
  value: string | undefined;
  onChange: (value: string) => void;
  buttonWidth: string;
  flexDirection?: "row" | "column";
}
function FieldButtonGroups({
  items,
  value,
  onChange,
  title,
  error,
  buttonWidth = "100%",
  flexDirection = "row",
}: FieldButtonGroupsProps) {
  return (
    <FieldTemplate gap={12} title={title} error={error}>
      <div
        css={{
          display: "flex",
          flexDirection: flexDirection,
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        {items.map((item, index) => (
          <VStack width={buttonWidth} key={index}>
            <OptionButton
              index={`${title}-${index}`}
              checked={value === item}
              label={item}
              onChange={() => onChange(item)}
            />
          </VStack>
        ))}
      </div>
    </FieldTemplate>
  );
}

const inputCss = css`
  width: 100%;
  text-align: left;
  padding: 13px 16px;
  border-radius: 8px;
  outline: none;
  background: ${colors.gray50};
  color: ${colors.black};

  ::placeholder {
    color: ${colors.gray500};
  }
`;

const outlinedCss = css`
  width: 100%;
  height: 44px;
  text-align: left;
  padding: 13px 16px;
  border-radius: 3px;
  outline: none;
  background: ${colors.white};
  border: 1px solid ${colors.gray200};
  color: ${colors.gray900};
  ::placeholder {
    color: ${colors.gray500};
  }
`;

const inputEmptyCss = css`
  background: ${colors.gray50};
  color: ${colors.gray500};
`;
const inputFillCss = css`
  background: ${colors.white};
  color: ${colors.black};
  border: 1px solid ${colors.gray300};
`;
