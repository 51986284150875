import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
} from "@chakra-ui/react";
import { ColorIcon, colors, VStack } from "goi_common";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import Menu from "./Menu";

interface GuidebookSummaryDrawerProps {
  open: boolean;
  onClose: () => void;
}

const GUIDEBOOK_SUMMARY_TABLE_OF_CONTENTS = [
  [
    {
      title: "0. 장례 절차 한눈에 보기",
      target: "0-1",
      items: [
        {
          title: "1. 장례 절차 한눈에 보기",
          target: "0-1",
        },
      ],
    },
    {
      title: "1. 장례 1일차",
      isAccordion: true,
      items: [
        {
          title: "1. 운명",
          target: "1-1",
        },
        {
          title: "2-1. 병원에서 돌아가신 경우",
          target: "1-2-1",
        },
        {
          title: "2-2. 자택에서 사망하신 경우",
          target: "1-2-2",
        },
        {
          title: "3. 안치",
          target: "1-3",
        },
        {
          title: "4. 상조&장례식장 계약",
          target: "1-4",
        },
        {
          title: "5. 빈소 설치",
          target: "1-5",
        },
        {
          title: "6. 부고 문자 작성",
          target: "1-6",
        },
      ],
    },
    {
      title: "2. 장례 2일차",
      isAccordion: true,
      items: [
        {
          title: "1. 입관식",
          target: "2-1",
        },
        {
          title: "2. 조문",
          target: "2-2",
        },
        {
          title: "3. 비용 정산",
          target: "2-3",
        },
      ],
    },
    {
      title: "3. 장례 3일차",
      isAccordion: true,
      items: [
        {
          title: "1. 발인",
          target: "3-1",
        },
        {
          title: "2. 운구",
          target: "3-2",
        },
        {
          title: "3-1. 화장하는 경우",
          target: "3-3-1",
        },
        {
          title: "3-2. 매장하는 경우",
          target: "3-3-2",
        },
        {
          title: "4. 장례식장 복귀",
          target: "3-4",
        },
      ],
    },
  ],

  [
    {
      title: "1. 상조 서비스의 3가지 유형",
      target: "1-1",
      items: [
        {
          title: "1. 빈소 유무에 따른 유형",
          target: "1-1",
        },
        {
          title: "2. 조문객 규모에 따른 유형",
          target: "1-2",
        },
      ],
    },
    {
      title: "2. 상조 서비스 비용 총정리",
      target: "2-1",
      items: [
        {
          title: "1. 장례 평균 금액",
          target: "2-1",
        },
        {
          title: "2. 인력에 따른 비용",
          target: "2-2",
        },
        {
          title: "3. 장례 용품에 따른 비용",
          target: "2-3",
        },
        {
          title: "4. 차량 사용 여부 및 거리에 따른 비용",
          target: "2-4",
        },
      ],
    },
    {
      title: "3. 믿을 수 있는 상조 업체를 찾는 방법",
      target: "3-1",
      items: [
        {
          title: "1. 믿을 수 있는 상조 업체를 찾는 방법",
          target: "3-1",
        },
      ],
    },
  ],
  [
    {
      title: "1. 장례식장 위치 선정",
      target: "1-1",
      items: [],
    },
    {
      title: "2. 빈소 크기 정하기",
      target: "1-2",
      items: [],
    },
    {
      title: "3. 장례식장 비용",
      target: "1-3",
      items: [],
    },
    {
      title: "4. 전국 장례식장 비용",
      target: "1-4",
      items: [],
    },
  ],
  [
    {
      title: "1. 장사 방법",
      target: "1-0",
      items: [
        {
          title: "1. 매장",
          target: "1-1",
        },
        {
          title: "2. 봉안",
          target: "1-2",
        },
        {
          title: "3. 자연장",
          target: "1-3",
        },
        {
          title: "4. 산골",
          target: "1-4",
        },
      ],
    },
    {
      title: "2. 장지 선택 시 주의할 점",
      target: "2-1",
      items: [],
    },
  ],
  [
    {
      title: "1. 장례 후 의례",
      target: "1-1",
      items: [
        {
          title: "1. 탈상",
          target: "1-1",
        },
        {
          title: "2. 삼우제",
          target: "1-2",
        },
        {
          title: "3. 사십구재(49재)",
          target: "1-3",
        },
      ],
    },
    {
      title: "2. 장례 후 행정",
      target: "2-1",
      items: [
        {
          title: "1. 사망신고",
          target: "2-1",
        },
        {
          title: "2. 고인 명의의 자산 이전",
          target: "2-2",
        },
        {
          title: "3. 고인 명의의 계약 해지",
          target: "2-3",
        },
      ],
    },
    {
      title: "3. 장례 후 재산처리",
      target: "3-1",
      items: [
        {
          title: "1. 안심상속 원스톱 서비스",
          target: "3-1",
        },
        {
          title: "2. 상속포기",
          target: "3-2",
        },
        {
          title: "3. 한정승인",
          target: "3-3",
        },
        {
          title: "4. 특별한정승인",
          target: "3-4",
        },
        {
          title: "5. 공동상속인의 특별한정승인",
          target: "3-5",
        },
      ],
    },
  ],
];

export function GuidebookSummaryDrawer({ open, onClose }: GuidebookSummaryDrawerProps) {
  const [current, setCurrent] = useState(0);
  const router = useRouter();
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const input = document.getElementById("home-input");
        input?.blur();
      }, 100);
    }
  }, [open]);

  const handleMenuItemClick = useCallback(
    (target: string) => {
      const summaryStep = [
        "/before/funeral-process",
        "/before/service-type",
        "/parlor",
        "/facility",
        "/after/checklist",
      ];
      router.push(`/guidebook/summary${summaryStep[current]}#${target}`).then(() => {
        onClose();
      });
    },
    [router, onClose, current],
  );
  return (
    <Drawer isOpen={open} onClose={onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        width="100% !important"
        height="532px"
        maxWidth="var(--global-max-width) !important"
        boxShadow="none"
        overflow="auto"
        borderRadius="10px 10px 0 0"
        marginLeft="var(--left)"
      >
        <VStack>
          <HStack
            padding="24px 20px 20px 24px"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${colors.gray100}`}
          >
            <Box width="85%">
              <Menu
                items={["장례 절차", "상조 서비스", "장례식장", "장지", "장례 후"]}
                current={current}
                setCurrent={setCurrent}
              />
            </Box>
            <Box as="button" onClick={onClose}>
              <ColorIcon name="icon-x-mono" color={colors.gray900} size={26} />
            </Box>
          </HStack>
          <MenuAccordions
            key={current}
            items={GUIDEBOOK_SUMMARY_TABLE_OF_CONTENTS[current]}
            handleMenuItemClick={handleMenuItemClick}
          />
        </VStack>
      </DrawerContent>
    </Drawer>
  );
}

const MenuAccordions = ({
  items,
  handleMenuItemClick,
}: {
  items: Array<any>;
  handleMenuItemClick: (target: string) => void;
}) => {
  return (
    <Accordion allowToggle>
      {items.map((item) => {
        return (
          <AccordionItem key={item.title} borderBottom={`1px solid ${colors.gray100}`}>
            <h2>
              <MenuAccodionButton
                isLastItem={item.items.length === 0}
                item={item}
                handleMenuItemClick={handleMenuItemClick}
              />
            </h2>
            <AccordionPanel p="0">
              <VStack>
                {item.items.map((item: any) => {
                  return (
                    <Box
                      onClick={() => handleMenuItemClick(item.target)}
                      style={{
                        cursor: "pointer",
                        color: colors.gray500,
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: 500,
                      }}
                      key={item.title}
                      p="10px 48px"
                      borderTop={`1px solid ${colors.gray100}`}
                    >
                      {item.title}
                    </Box>
                  );
                })}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

const MenuAccodionButton = ({
  isLastItem,
  item,
  handleMenuItemClick,
}: {
  isLastItem: boolean;
  item: { target: string; title: string };
  handleMenuItemClick: (target: string) => void;
}) => {
  return (
    <AccordionButton
      className="button2 gray900"
      padding="10px 24px"
      _expanded={{
        bg: colors.warmGray50,
        color: colors.orange650,
      }}
      onClick={() => {
        isLastItem && handleMenuItemClick(item.target);
      }}
    >
      <Box flex="1" textAlign="left">
        {item.title}
      </Box>
    </AccordionButton>
  );
};
