import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";
import { flexCentering, Button, EventProps } from "goi_common";

interface FooterProps {
  text: string;
  onClick: () => void;
  event?: EventProps;
}

type FooterButtonType = "left" | "right" | undefined;

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  type: number;
  header?: string | React.ReactNode;
  children: React.ReactNode;
  footerProps: FooterProps | [FooterProps, FooterProps];
  isLoading?: boolean;
  closeOnOverlayClick?: boolean;
}

function Dialog({
  isOpen,
  onClose,
  type,
  header,
  children,
  footerProps,
  isLoading = false,
  closeOnOverlayClick,
}: DialogProps) {
  const { headerStyle, bodyStyle } = useMemo(() => {
    if (type === 1) {
      return {
        headerStyle: {
          justifyContent: "center",
          fontSize: "18px",
          lineHeight: "28px",
          color: "var(--black)",
        },
        bodyStyle: {
          padding: "20px 0 24px",
        },
      };
    } else if (type === 2) {
      return {
        headerStyle: {
          justifyContent: "flex-start",
          fontSize: "16px",
          lineHeight: "26px",
          color: "var(--gray900)",
        },
        bodyStyle: {
          padding: "8px 0",
        },
      };
    } else if (type === 3) {
      return {
        headerStyle: {
          justifyContent: "center",
          fontSize: "16px",
          lineHeight: "26px",
          color: "var(--gray900)",
        },
        bodyStyle: {
          padding: "8px 0",
        },
      };
    }

    return {};
  }, [type]);

  const footer = useMemo(() => {
    if (Array.isArray(footerProps)) {
      return (
        <>
          <FooterButton type="left" onClick={footerProps[0].onClick} className="subtitle3">
            {footerProps[0].text}
          </FooterButton>
          {isLoading ? (
            <FooterButton type="right">
              <LoadingDonut />
            </FooterButton>
          ) : (
            <FooterButton type="right" onClick={footerProps[1].onClick} className="subtitle3">
              {footerProps[1].text}
            </FooterButton>
          )}
        </>
      );
    } else {
      if (isLoading) {
        return (
          <FooterButton>
            <LoadingDonut />
          </FooterButton>
        );
      } else {
        return (
          <FooterButton
            onClick={footerProps.onClick}
            className="subtitle3"
            event={footerProps.event}
          >
            {footerProps.text}
          </FooterButton>
        );
      }
    }
  }, [footerProps, isLoading]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.5)" />
        <ModalContent width="280px !important" p="40px 20px 20px">
          <ModalHeader
            whiteSpace="pre-line"
            display="flex"
            fontWeight="700"
            p="0"
            {...headerStyle}
            textAlign="center"
          >
            {header}
          </ModalHeader>

          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            whiteSpace="nowrap"
            {...bodyStyle}
            className="body3_r"
          >
            {children}
          </ModalBody>

          <ModalFooter display="flex" justifyContent="space-between" p="0">
            {footer}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const buttonTypeToStyle = (type: FooterButtonType) => {
  switch (type) {
    case "left":
      return css`
        width: calc((100% - 8px) / 2);
        border: 1px solid var(--gray600);
        border-radius: 2px;
        background: var(--white);
        color: var(--black);
      `;
    case "right":
      return css`
        width: calc((100% - 8px) / 2);
        border: 1px solid transparent;
        border-radius: 2px;
        background: var(--black);
        color: var(--white);
      `;

    default:
      return css`
        width: 100%;
        border: 1px solid transparent;
        border-radius: 2px;
        background: var(--black);
        color: var(--white);
      `;
  }
};

export const LoadingDonut = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 4px solid var(--white);
  border-top-color: var(--black);
  animation: 1.5s spin infinite linear;
`;

const FooterButton = styled(Button)<{ type?: FooterButtonType }>`
  ${flexCentering("row")}
  height: 50px;
  cursor: pointer;

  ${({ type }) => buttonTypeToStyle(type)}
`;

export default Dialog;
