import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import {
  cooKeys,
  FUNERAL_SIDO,
  FUNERAL_SIGUNGU,
  getCookie,
  setCookie,
} from "goi_common";
import { useEffect } from "react";

export function ChatbotModalContent({ onClose }: { onClose: () => void }) {
  // const [value, setvalue] = useState("");

  const handleClick = () => {
    const requestBody = getCookie(cooKeys.quotation_request);
    let answer = "";
    if (requestBody) {
      answer += `지역: ${
        FUNERAL_SIDO.get(Number(requestBody.funeral_sido)) as string
      } ${
        FUNERAL_SIGUNGU.get(Number(requestBody.funeral_sido))?.get(
          Number(requestBody.funeral_sigungu)
        ) as string
      }\n`;
      answer += `조문객수: ${requestBody.condolers}\n`;
      answer += `상황: ${requestBody.status}\n`;
    }

    ChannelService.updateUser(
      {
        profile: {
          answer: answer,
        },
      },
      () => {
        ChannelService.openChat();
      }
    );
  };

  useEffect(() => {
    ChannelService.boot({
      pluginKey: process.env.NEXT_PUBLIC_CHANNELTALK_PLUGIN_KEY as string,
    });

    handleClick();

    ChannelService.onHideMessenger(() => {
      onClose();
    });

    setCookie("isChannelTalkOpen", "TRUE");

    return () => {
      ChannelService.shutdown();
    };
  }, []);

  return (
    <div
      css={{
        padding: "20px",
        background: "#00000099",
      }}
      className="subtitle_14_b gray800"
    >
      채팅 상담이 진행중입니다.
    </div>
  );
}

export default ChatbotModalContent;
