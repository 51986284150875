import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  call,
  ColorIcon,
  colors,
  customEvent,
  EventProps,
  getCdnImageSrc,
  GoiLogo,
  HStack,
  NextLink,
  useResponsive,
  VStack,
} from "goi_common";
import { ReviewCard } from "./ReviewCard";

import { accountState } from "@/atoms/accountState";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useRecoilValueLoadable } from "recoil";

import { Axios } from "@/utils/axios";
import dayjs from "dayjs";
import { useQuery } from "react-query";
interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

interface DrawerItemProps {
  href: string;
  icon?: ReactNode;
  title: ReactNode;
  event?: EventProps;
}
function Menu({ isOpen, onClose }: MenuProps) {
  const router = useRouter();

  const { isBiggerThanMd } = useResponsive();

  const account = useRecoilValueLoadable(accountState);
  const login = () => {
    router.push(`/login?redirectTo=${encodeURIComponent(router.asPath)}`);
    onClose();
  };

  const DrawerItem = ({ href, icon, title, event }: DrawerItemProps) => {
    return (
      <DrawerItemRoot
        href={href}
        onClick={() => {
          onClose();
          event && customEvent(event);
        }}
      >
        {icon}
        <Box className="subtitle_14_b" marginLeft="12px">
          {title}
        </Box>
      </DrawerItemRoot>
    );
  };
  const { data } = useQuery(["funeral_reviews"], () => {
    return Axios.get("/sangjo_reviews/");
  });
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay bg="rgba(0, 0, 0, 0.5)" />
      <DrawerContent width="100%!important" maxWidth={isBiggerThanMd ? "360px" : "100%"}>
        <DrawerHeader
          flexDirection="column"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          p="11px 20px 11px 28px"
        >
          <HStack justifyContent="space-between" alignItems="center" width="100%">
            <HStack alignItems="center" gap={24}>
              <HStack alignItems="center" gap={8}>
                <GoiLogo />
              </HStack>
              <Box className="body3_r" display="flex" flexDirection="row" alignItems="center">
                {account.state === "hasValue" && !account.contents ? (
                  <HStack alignItems="center" onClick={login} color={colors.gray700}>
                    <span>로그인/회원가입</span>{" "}
                    <ColorIcon name="drawer_next" size={16} color="#999999" />
                  </HStack>
                ) : (
                  <HStack
                    gap={6}
                    alignItems="center"
                    onClick={() => {
                      router.push("/my/");
                      customEvent({ newGtm: { path: "gnb", trigger_and_target: "mypage-uv_my" } });
                      onClose();
                    }}
                    color={colors.gray700}
                  >
                    <span className="subtitle_16_b gray900">
                      {account.contents.nickname + "님"}
                    </span>
                    <HStack alignItems="center">
                      <span>마이페이지/고이의 추천</span>{" "}
                      <ColorIcon name="drawer_next" size={16} color="#999999" />
                    </HStack>
                  </HStack>
                )}
              </Box>
            </HStack>
            <Button
              onClick={onClose}
              style={{ width: "40px", height: "40px", padding: "0px", background: "none" }}
            >
              <ColorIcon name="header-close" size={20} color={colors.gray800} />
            </Button>
          </HStack>
        </DrawerHeader>
        <DrawerSpacer />

        <DrawerBody p="0">
          <DrawerItem
            href="/service/"
            icon={
              <Box margin="0 -3px 0 -4px">
                <ColorIcon name="drawer_goi_service" size={32} color={colors.gray900} />
              </Box>
            }
            title={
              <>
                고이상조 서비스 전체 보기<span className="orange650">(사전상담할인가)</span>
              </>
            }
            event={{ newGtm: { path: "gnb", trigger_and_target: "drawer-service" } }}
          />
          <DrawerItem
            href="/match/request/"
            icon={<ColorIcon name="drawer_guidebook" size={24} color={colors.gray900} />}
            title={
              <>
                장례 전체 비용 견적, <span className="orange650">맞춤형 혜택</span> 알아보기
              </>
            }
            event={{ newGtm: { path: "gnb", trigger_and_target: "drawer-mtch_rqst" } }}
          />

          <DrawerItem
            href="/guidebook/"
            icon={<ColorIcon name="drawer_estimate" size={24} color={colors.gray900} />}
            title="장례의 모든 정보, 무료 온라인 가이드북"
            event={{ newGtm: { path: "gnb", trigger_and_target: "drawer-guidebook" } }}
          />

          <DrawerSpacer />
          <VStack padding="12px 12px 12px 28px" background={colors.gray50}>
            <HStack justifyContent="space-between" alignItems="center">
              <Box className="subtitle_16_b gray900">
                실제 후기
                <sup className="subtitle_12_b orange650">NEW</sup>
              </Box>
              <HStack
                gap={6}
                alignItems="center"
                onClick={() => {
                  router.push("/review/");
                  customEvent({ newGtm: { path: "gnb", trigger_and_target: "review-uv_review" } });

                  onClose();
                }}
              >
                <span className="caption_12_b gray500">전체보기</span>
                <ColorIcon name="icon-arrow-right-circle-mono" color={colors.gray500} size={14} />
              </HStack>
            </HStack>
            <Box className="caption_11_sb danger" pb="6px">
              {data && dayjs(data.data.results[0].created_at).format("YYYY년 MM월 DD일 HH시 mm분")}{" "}
              <span className="caption_11_m gray600">직접 작성하셨습니다.</span>
            </Box>
            <HStack>
              <HStack gap={12} padding="0 12px 1px 0" width="100%">
                {data && data.data && (
                  <Box boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)" borderRadius="6px">
                    <ReviewCard
                      cardWidth="100%"
                      title={data.data.results[0].headline}
                      content={data.data.results[0].good}
                      user={data.data.results[0].user}
                      score={Number(data.data.results[0].score || 5)}
                      onClick={() => {
                        customEvent({
                          newGtm: {
                            path: "gnb",
                            trigger_and_target: "recent_review-uv_review_detail",
                            text: String(data.data.results[0].id),
                          },
                          triggerHackle: true,
                        });
                        router.push(`/review/${data.data.results[0].id}`);
                        onClose();
                      }}
                    />
                  </Box>
                )}
              </HStack>
            </HStack>
            <Box className="caption_12_m gray800" pt="6px">
              장례를 치른 이후 고이 후기 페이지에서 고객님들이 직접 작성해주시는{" "}
              <b className="danger">실제 후기</b>
              입니다.
            </Box>
          </VStack>
          <DrawerSpacer />
          <div style={{ marginTop: "12px" }} />
          <VStack overflow="hidden">
            <Box padding="0 0 0 28px" className="subtitle_14_b">
              장례 준비, 믿고 맡길 수 있는지 충분히 알아보세요.
            </Box>
            <Box overflow="scroll">
              <HStack overflow="scroll" width="max-content" padding="12px 24px" gap={6}>
                <VStack
                  width="120px"
                  borderRadius="6px"
                  boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)"
                  onClick={() => {
                    router.push("/funeral-prepare/1/");
                    customEvent({
                      newGtm: { path: "gnb", trigger_and_target: "content-uv_prepare_1" },
                    });
                    onClose();
                  }}
                >
                  <Box
                    borderRadius="6px 6px 0px 0px"
                    width="100%"
                    height="120px"
                    backgroundImage={getCdnImageSrc("platform/prepare/prepare-1-2.png")}
                    backgroundSize="contain"
                  ></Box>
                  <Box width="100%" padding="8px 8px" className="caption_12_b gray700">
                    처음하는 장례 준비, 어떻게 시작해야할까요?
                  </Box>
                </VStack>
                <VStack
                  width="120px"
                  borderRadius="6px"
                  boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)"
                  onClick={() => {
                    router.push("/guide/how-about-parlor-funeral/");
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target: "content-uv_how_about_parlor_funeral",
                      },
                    });
                    onClose();
                  }}
                >
                  <Box
                    borderRadius="6px 6px 0px 0px"
                    width="100%"
                    height="120px"
                    backgroundImage={getCdnImageSrc("platform/prepare/prepare-1-7.png")}
                    backgroundSize="contain"
                  ></Box>
                  <Box width="100%" padding="8px 8px" className="caption_12_b gray700">
                    장례식장에서 장례를 치를 때, 빈소 계약시 주의할 점
                  </Box>
                </VStack>
                <VStack
                  width="120px"
                  borderRadius="6px"
                  boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)"
                  onClick={() => {
                    router.push("/guide/about-parlor-funeral/");
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target: "content-uv_about_parlor_funeral",
                      },
                    });
                    onClose();
                  }}
                >
                  <Box
                    borderRadius="6px 6px 0px 0px"
                    width="100%"
                    height="120px"
                    backgroundImage={getCdnImageSrc("platform/prepare/prepare-1-1.png")}
                    backgroundSize="contain"
                  ></Box>
                  <Box width="100%" padding="8px 8px" className="caption_12_b gray700">
                    상조 없이 장례식장에서 장례를 진행하면 더 저렴할까요?
                  </Box>
                </VStack>

                <VStack
                  width="120px"
                  borderRadius="6px"
                  boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)"
                  onClick={() => {
                    router.push("/funeral-prepare/2/");
                    customEvent({
                      newGtm: { path: "gnb", trigger_and_target: "content-uv_prepare_2" },
                    });
                    onClose();
                  }}
                >
                  <Box
                    borderRadius="6px 6px 0px 0px"
                    width="100%"
                    height="120px"
                    backgroundImage={getCdnImageSrc("platform/home/carousel-2.png")}
                    backgroundSize="contain"
                  ></Box>
                  <Box width="100%" padding="8px 8px" className="caption_12_b gray700">
                    선불제 상조보다 100만~200만원 저렴한 후불제 상조에 대해 알아보기
                  </Box>
                </VStack>
                <VStack
                  width="120px"
                  borderRadius="6px"
                  boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)"
                  onClick={() => {
                    router.push("/funeral-prepare/company-introduction/");
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target: "content-uv_company_introduction",
                      },
                    });
                    onClose();
                  }}
                >
                  <Box
                    borderRadius="6px 6px 0px 0px"
                    width="100%"
                    height="120px"
                    backgroundImage={getCdnImageSrc(
                      "images/company-introduction/company-introduction-1.png",
                    )}
                    backgroundSize="contain"
                  ></Box>
                  <Box width="100%" padding="8px 8px" className="caption_12_b gray700">
                    투명하고 정직한 장례. 고이장례연구소에 대해 알아보기
                  </Box>
                </VStack>
                <VStack
                  width="120px"
                  borderRadius="6px"
                  boxShadow="0px 0px 4px rgba(0, 0, 0, 0.12)"
                  onClick={() => {
                    router.push("/funeral-prepare/funeral-cost/");
                    customEvent({
                      newGtm: { path: "gnb", trigger_and_target: "content-uv_funeral_cost" },
                    });
                    onClose();
                  }}
                >
                  <Box
                    borderRadius="6px 6px 0px 0px"
                    width="100%"
                    height="120px"
                    backgroundImage={getCdnImageSrc("platform/home/carousel-1.png")}
                    backgroundSize="contain"
                  ></Box>
                  <Box width="100%" padding="8px 8px" className="caption_12_b gray700">
                    고이장례연구소에서는 장례 비용을 어떻게 500만원, 1000만원까지 줄일 수 있나요?
                  </Box>
                </VStack>
              </HStack>
            </Box>
          </VStack>
          <DrawerSpacer />
        </DrawerBody>
        <DrawerFooter padding="12px 8px">
          <StyledButton
            width="100%"
            bgColor={colors.green100}
            color={colors.black}
            borderRadius={5}
            height="60px"
            padding="6px"
            marginBottom="6px"
            onClick={() => {
              call("1666-9784", true);
            }}
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row" alignItems="center" margin="auto" pl="32px">
              <ColorIcon color={colors.green350} name="drawer_cs" size={26} />
              <Box ml="4px">24시간 상담 / 임종 접수</Box>
            </Box>
            <ColorIcon color={colors.black} name="drawer_next" size={32} />
          </StyledButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

const DrawerSpacer = styled.div`
  width: 100%;
  height: 6px;
  background: var(--gray50);
`;

const DrawerItemRoot = styled(NextLink)`
  margin-left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  = :active {
    background: var(--gray50);
  }

  .body3_m {
    padding-left: 12px;
  }
`;

const StyledButton = styled(Button)`
  transition: 0.3s ease-out;
  &:hover {
    background-color: var(--green150);
    transition: 0.3s ease-out;
  }
`;

export default Menu;
