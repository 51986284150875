import React, { ChangeEvent, HTMLAttributes, ReactNode } from "react";
import styled from "@emotion/styled";
import { HStack } from "./HStack";
import { Icon } from "./Icons";

interface BaseProps {
  index?: number | string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  alignItem?: string;
  width?: string;
}

type Props = BaseProps &
  Omit<HTMLAttributes<HTMLInputElement>, keyof BaseProps>;

export const Radio = ({
  index,
  checked,
  label,
  className = "body1 gray700",
  alignItem = "start",
  width,
  ...rest
}: Props) => {
  const id = `radio-${index ?? 0}`;

  return (
    <>
      <HiddenInput type="radio" checked={checked} id={id} {...rest} />
      <StyledLabel htmlFor={id}>
        <HStack gap={10} alignItems={alignItem}>
          <RadioIcon
            alt="radio"
            src={checked ? "/radio_fill.svg" : "/radio_empty.svg"}
            width={26}
            height={26}
          />
          <Label className={className} style={{ width: width }}>
            {label}
          </Label>
        </HStack>
      </StyledLabel>
    </>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  width: fit-content;

  cursor: pointer;
`;

const Label = styled.div`
  white-space: pre-line;
  width: 100%;
`;

const RadioIcon = styled(Icon)`
  flex-shrink: 0;
`;
