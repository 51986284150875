import { useCallback, useMemo } from "react";

interface IUseTablePaginationArgs {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalCount: number | undefined;
  limit?: number;
  maxShowPage: number;
}

function useTablePagination({
  currentPage,
  setCurrentPage,
  totalCount,
  limit = 20,
  maxShowPage = 10,
}: IUseTablePaginationArgs) {
  const totalPages = useMemo(() => {
    return Math.floor(((totalCount || 1) - 1) / limit + 1);
  }, [totalCount, limit]);
  const baseNumber = useMemo(() => {
    return Math.floor((currentPage - 1) / maxShowPage);
  }, [currentPage, maxShowPage]);
  const onClickPrevious = useCallback(() => {
    setCurrentPage(baseNumber * maxShowPage);
  }, [setCurrentPage, baseNumber, maxShowPage]);
  const onClickNext = useCallback(() => {
    setCurrentPage((baseNumber + 1) * maxShowPage + 1);
  }, [setCurrentPage, baseNumber, maxShowPage]);
  return {
    baseNumber,
    currentPage,
    onClickPrevious,
    onClickNext,
    setCurrentPage,
    totalPages,
  };
}
export default useTablePagination;
