import { ColorIcon, colors, useResponsive, VStack } from "goi_common";
import { ReactNode } from "react";

export default function ServiceModal({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  const { setResponsive } = useResponsive();

  return (
    <>
      {isOpen && (
        <VStack
          position="fixed"
          css={{ top: 0, left: 0 }}
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          zIndex={1000}
        >
          <VStack
            background="white"
            width={setResponsive("100%", "360px")}
            height={setResponsive("100%", "650px")}
            borderRadius="10px"
            position="relative"
            alignItems="center"
            className="body3_r"
            padding="0px"
            zIndex={9}
          >
            <VStack
              width="100%"
              height="58px"
              css={{ flexShrink: 0 }}
              background="white"
              borderRadius="10px"
            >
              <VStack
                css={{ position: "absolute", right: 20, top: 20, cursor: "pointer" }}
                onClick={onClose}
              >
                <ColorIcon name="icon-x-mono" size={20} color={colors.gray900} />
              </VStack>
            </VStack>
            <VStack
              borderRadius="10px"
              width="100%"
              css={{
                overflowY: "scroll",
                [`::-webkit-scrollbar`]: {
                  display: "none",
                },
              }}
            >
              {children}
            </VStack>
          </VStack>

          <VStack
            position="absolute"
            background="rgba(0,0,0,0.5)"
            width="100vw"
            height={`calc(var(--vh, 1vh) * 100)`}
            onClick={onClose}
            css={{ top: 0, left: 0 }}
            zIndex={8}
          ></VStack>
        </VStack>
      )}
    </>
  );
}
