import React, { useEffect, useRef, useCallback } from "react";

interface IframeProps extends React.HTMLAttributes<HTMLIFrameElement> {
  onInferredClick: (iframe: HTMLIFrameElement) => void;
  src: string;
  width: string;
  height: string;
  position: "absolute" | "relative";
}

export function EventIframe({
  onInferredClick,
  src,
  width,
  height,
  position,
}: IframeProps): React.ReactElement {
  const iframeRef = useRef<null | HTMLIFrameElement>(null);

  const iframeCallbackRef = useCallback(
    (node: null | HTMLIFrameElement): void => {
      iframeRef.current = node;
    },
    []
  );

  useEffect(() => {
    const onBlur = () => {
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === "iframe" &&
        iframeRef.current &&
        iframeRef.current === document.activeElement
      ) {
        onInferredClick(iframeRef.current);
      }
    };

    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return (
    <iframe
      css={{ width, height, position }}
      src={src}
      ref={iframeCallbackRef}
    />
  );
}
