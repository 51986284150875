export default {
  baseStyle: {
    _focus: {
      boxShadow: "none",
    },
  },

  sizes: {},
  variants: {},
  defaultProps: {},
};
