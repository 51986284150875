import { CommonEventProps, CompositeEventTypes } from "../types";

export const lastCheckFunnels: Record<
  CompositeEventTypes,
  { pass: boolean; event: CommonEventProps }[]
> = {
  "funnel-parlor_rqst-region-signup": [
    {
      pass: false,
      event: {
        path: "parlor_detail_request",
        trigger_and_target: "CTA",
      },
    },
    {
      pass: false,
      event: {
        path: "parlor_region",
        trigger_and_target: "CTA_btm_total_price-uv_match_request",
      },
    },
    {
      pass: false,
      event: {
        action: "match_request_complete",
      },
    },
    {
      pass: false,
      event: {
        action: "signup",
      },
    },
  ],
  "funnel-uv_match_rqst-submit_signup": [
    {
      pass: false,
      event: {
        path: "match_request",
        trigger_and_target: "submit_conversion",
      },
    },
    {
      pass: false,
      event: {
        action: "signup",
      },
    },
  ],
};
