import { RefObject, useCallback, useEffect } from "react";

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  callback: () => void
) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) callback();
    },
    [callback, ref]
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => window.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
}
