import { css } from "@emotion/react";
import { HStack } from "./HStack";
import { VStack } from "./VStack";
import { motion } from "framer-motion";

interface TabBarProps {
  gap?: number;
  children: React.ReactNode;
  height?: string;
  width?: string;
  justifyContent?: string;
  css?: any;
}

export function TabBar({
  gap,
  children,
  height,
  width,
  justifyContent,
  css,
}: TabBarProps) {
  return (
    <HStack
      css={css}
      height={height}
      gap={gap}
      width={width}
      justifyContent={justifyContent}
    >
      {children}
    </HStack>
  );
}

interface TabProps {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  isActive?: boolean;
  activeBarStyle?: object;
  activeFontStyle?: object;
  activeBackgroundColor?: string;
  defaultBackgroundColor?: string;
  style?: object;
  height?: string;
  justifyContent?: string;
  layoutId?: string;
}

function Tab({
  onClick,
  children,
  className,
  isActive,
  activeBarStyle,
  activeFontStyle,
  activeBackgroundColor,
  defaultBackgroundColor,
  style,
  height,
  justifyContent,
  layoutId,
}: TabProps) {
  return (
    <VStack
      style={style}
      {...style}
      position="relative"
      onClick={onClick}
      css={tabCss}
      height={height}
      justifyContent={justifyContent}
      background={
        isActive && activeBackgroundColor
          ? activeBackgroundColor
          : defaultBackgroundColor
      }
    >
      <div className={className} css={isActive ? { ...activeFontStyle } : {}}>
        {children}
      </div>
      {isActive ? (
        <motion.div
          layoutId={layoutId}
          transition={{
            opacity: { ease: "linear" },
            layout: { duration: 0.2 },
          }}
          css={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            ...activeBarStyle,
          }}
        ></motion.div>
      ) : null}
    </VStack>
  );
}

TabBar.Tab = Tab;

const tabCss = css({
  userSelect: "none",
  cursor: "pointer",
});
