import { forwardRef, ForwardRefRenderFunction, Ref } from "react";
import { ComponentWithAs, PropsWithAs } from "../types/as";

export function forwardRefWithAs<
  Props extends object,
  DefaultElement extends keyof JSX.IntrinsicElements,
  StaticMember extends object = Record<string, never>
>(
  component: ForwardRefRenderFunction<any, PropsWithAs<Props, DefaultElement>>
) {
  return forwardRef(component) as unknown as ComponentWithAs<
    Props & { ref?: Ref<any> },
    DefaultElement
  > &
    StaticMember;
}
