import { Box, Flex } from "@chakra-ui/react";
import {
  colors,
  customEvent,
  HStack,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  useResponsive,
  VStack,
} from "goi_common";
import { useRouter } from "next/router";

function TopBanner({
  path,
}: {
  path:
    | "home"
    | "review"
    | "review_detail"
    | "mtch_recomm_lst"
    | "parlor_detail"
    | "facility_detail"
    | "guidebook"
    | "guidebook_detail"
    | "guide"
    | "service";
}) {
  const router = useRouter();
  const { isBiggerThanMd } = useResponsive();
  return isBiggerThanMd ? (
    <Flex
      width="100%"
      background={colors.green400}
      alignItems="center"
      justifyContent="center"
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        padding="24px"
        maxWidth="1200px"
      >
        <Box color={colors.white} className="subtitle_21_b">
          새벽에도, 명절에도 24시간 대기하고 있습니다
          <br />
          <Box color={colors.white} className="subtitle_18_sb" pt="12px">
            전국 어디든 출동합니다
          </Box>
        </Box>
        <VStack gap={12} width="300px" padding="0px 24px">
          <LargeButton
            buttonType={LARGEL_BUTTON_TYPE.GRAY900}
            height={36}
            onClick={() => {
              customEvent({
                newGtm: {
                  path: path as any,
                  trigger_and_target: "CTA_banner-call",
                },
              });
              router.push("/review/");
            }}
            className="button_16_sb"
          >
            고객님들의 후기 보기
          </LargeButton>
          <LargeButton
            buttonType={LARGEL_BUTTON_TYPE.GRAY900}
            height={36}
            onClick={() => {
              customEvent({
                newGtm: {
                  path: path as any,
                  trigger_and_target: "CTA_banner-uv_match_request",
                },
              });
              router.push("/match/request/");
            }}
            className="button_16_sb"
          >
            견적 받기
          </LargeButton>
        </VStack>
      </HStack>
    </Flex>
  ) : (
    <VStack
      background={colors.green400}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      padding="24px 24px 12px"
    >
      <Box color={colors.white} className="subtitle_21_b" width="100%">
        새벽에도, 명절에도
        <br />
        24시간 대기하고 있습니다
        <br />
        <Box color={colors.white} className="subtitle_16_sb">
          전국 어디든 출동합니다
        </Box>
      </Box>
      <VStack gap={12} maxWidth="300px" width="100%" padding="12px 0">
        <LargeButton
          buttonType={LARGEL_BUTTON_TYPE.GRAY900}
          height={42}
          onClick={() => {
            customEvent({
              newGtm: {
                path: path as any,
                trigger_and_target: "CTA_banner-call",
              },
            });
            router.push("/review/");
          }}
          className="button_16_sb"
        >
          고객님들의 후기 보기
        </LargeButton>
        <LargeButton
          buttonType={LARGEL_BUTTON_TYPE.GRAY900}
          height={42}
          onClick={() => {
            customEvent({
              newGtm: {
                path: path as any,
                trigger_and_target: "CTA_banner-uv_match_request",
              },
            });
            router.push("/match/request/");
          }}
          className="button_16_sb"
        >
          견적 받기
        </LargeButton>
      </VStack>
    </VStack>
  );
}

export { TopBanner };
