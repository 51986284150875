import { ReactNode } from "react";
import { atom } from "recoil";

export const previewPackageState = atom({
  default: "",
  key: "previewPackageTags",
});

export const previewCreatePackageState = atom({
  default: "",
  key: "createPackageTags",
});

export const proposalPreviewPackageState = atom({
  default: "",
  key: "proposalPreviewPackageTags",
});

interface ServiceDialogProps {
  open: boolean;
  children: ReactNode | null;
}

export const serviceDialogState = atom<ServiceDialogProps>({
  default: {
    open: false,
    children: null,
  },
  key: "serviceDialogProps",
});
