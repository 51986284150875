import {
  previewCreatePackageState,
  previewPackageState,
  proposalPreviewPackageState,
  serviceDialogState,
} from "@/atoms/previewDialogState";
import { ReactNode } from "react";
import { useSetRecoilState } from "recoil";

export function useServiceDialog() {
  const setServiceDialogProps = useSetRecoilState(serviceDialogState);
  const setOpenPreviewPackage = useSetRecoilState(previewPackageState);
  const setOpenPreviewProposalPackage = useSetRecoilState(proposalPreviewPackageState);
  const setOpenPreviewCreatePackage = useSetRecoilState(previewCreatePackageState);
  const onOpen = ({ children }: { children: ReactNode }) => {
    setServiceDialogProps({
      open: true,
      children: children,
    });
  };
  const onClose = () => {
    setServiceDialogProps({
      open: false,
      children: null,
    });
    setOpenPreviewPackage("");
    setOpenPreviewProposalPackage("");
    setOpenPreviewCreatePackage("");
  };
  return { onOpen, onClose };
}
