import { colors } from "goi_common";

export function Chip({
  background,
  color,
  onClick,
  text,
  borderColor,
  padding = "8px 20px",
  className = "subtitle_14_b",
}: {
  background: string;
  color: string;
  onClick: () => void;
  text: string;
  borderColor?: string;
  padding?: string;
  className?: string;
}) {
  return (
    <button
      onClick={onClick}
      css={{
        width: "auto",
        whiteSpace: "nowrap",
        border: `1px solid ${borderColor || colors.gray50}`,
        padding,
        borderRadius: "50px",
        userSelect: "none",
        background: `${background}`,
        color: `${color}`,
        transitionProperty: "border-color, background-color, color",
        transitionDuration: "0.2s",
      }}
      className={className}
    >
      {text}
    </button>
  );
}
