import { isLoggedInState } from "@/atoms/accountState";
import { getCookie, useResponsive, VStack } from "goi_common";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";

import GuidebookSummaryHeader from "./GuidebookSummaryHeader";

import { Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import StepGuideFooter from "../common/StepGuideFooter";

import { Header } from "./Header";
import Menu from "./Menu";
import { Navigator } from "./Navigator";

interface LayoutProps {
  children: React.ReactNode;
}
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import { useOverlay } from "@toss/use-overlay";
import { useOffDirectCall } from "@/hooks/useOffDirectCall";

ChannelService.loadScript();

export default function Layout({ children }: LayoutProps) {
  const router = useRouter();
  const { isBiggerThanMd } = useResponsive();
  const overlay = useOverlay();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isGuideBookSummary = useMemo(() => {
    return router.asPath.startsWith("/guidebook/summary/");
  }, [router]);

  const 오인지_최소화_기능 = useOffDirectCall();
  const workshopHidden =
    오인지_최소화_기능 &&
    (router.asPath.includes("facilities") || router.asPath.includes("facility"));

  const isHeaderHidden = useMemo(() => {
    return (
      router.route === "/login" ||
      router.route === "/login-redirect" ||
      isGuideBookSummary ||
      router.pathname === "/parlor/[id]" ||
      router.pathname === "/crematorium/[id]" ||
      router.pathname === "/match/facility-requests" ||
      router.pathname === "/funeral-prepare/1" ||
      router.asPath.startsWith("/partners/") ||
      workshopHidden
    );
  }, [router, workshopHidden]);

  const isNavHidden = useMemo(() => {
    return (
      router.route === "/" ||
      router.route === "/login" ||
      router.route === "/login-redirect" ||
      router.route === "/benefit/prepare-package" ||
      router.pathname === "/funeral/[id]" ||
      router.pathname === "/review/[id]" ||
      router.route === "/consult/[id]" ||
      router.asPath.startsWith("/information-price/parlor/") ||
      (router.asPath.startsWith("/parlor/") && !router.asPath.startsWith("/parlors/region")) ||
      router.asPath.startsWith("/step-guide/") ||
      router.asPath.startsWith("/guide/") ||
      router.asPath.startsWith("/guidebook/tip") ||
      (router.asPath.startsWith("/match/") &&
        !router.asPath.startsWith("/match/recommendations")) ||
      router.asPath.startsWith("/match-compare/") ||
      router.route === "/notice/1" ||
      router.asPath.startsWith("/partners/") ||
      router.asPath.includes("flower") ||
      router.asPath.includes("bugo") ||
      router.asPath.includes("/refund/") ||
      router.asPath.includes("/promotion/service") ||
      router.asPath.includes("/promotion/parlor/50") ||
      router.pathname === "/funeral-prepare/1" ||
      router.asPath.includes("/facility-relocate/") ||
      workshopHidden
    );
  }, [router, workshopHidden]);

  const isStepGuideFooterVisible = useMemo(() => {
    return router.route.startsWith("/guidebook") && !router.route.includes("/guidebook/tip/");
  }, [router]);
  const isLoggedIn = useRecoilValue(isLoggedInState);

  const quotationRequestCRMDrawerOpen = () => {
    const isLoginPath = router.asPath.startsWith("/login");

    if (isLoggedIn || isLoginPath) return;
  };

  useEffect(() => {
    if (!router.asPath.includes("login")) {
      ChannelService.showChannelButton();
    }
    quotationRequestCRMDrawerOpen();
  }, [router.asPath]);

  useEffect(() => {
    if (getCookie("isChannelTalkOpen") === "TRUE") {
      ChannelService.boot({
        pluginKey: process.env.NEXT_PUBLIC_CHANNELTALK_PLUGIN_KEY as string,
      });
    }
    ChannelService.onShowMessenger(() => {
      overlay.open(({ isOpen, close: onClose }) => {
        return (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              onClose();
            }}
            closeOnOverlayClick={false}
            scrollBehavior="inside"
            isCentered={true}
            autoFocus={false}
            closeOnEsc={false}
            returnFocusOnClose={false}
          >
            <ModalOverlay width="100%" height="100%" />
          </Modal>
        );
      });
    });
    ChannelService.onHideMessenger(() => {
      overlay.close();
    });

    return () => {
      ChannelService.shutdown();
    };
  }, []);

  const isHasBottomFixedButtonGuidePath =
    router.asPath.includes("guidebook/summary/before/funeral-process") ||
    router.route === "/guidebook";

  return (
    <>
      {!isHeaderHidden && <Header onOpen={onOpen} />}
      {isGuideBookSummary && <GuidebookSummaryHeader />}
      <VStack>{children}</VStack>

      {!isNavHidden && <Navigator />}

      {isStepGuideFooterVisible && (
        <StepGuideFooter
          text={
            <>
              장례가 처음이신가요? 합리적인 장례,
              <br />
              차근차근 알려드릴게요.
            </>
          }
          size="30px"
          src="/step-guide/facility-initiation/"
          event={{
            newGtm: {
              path: "step_guide",
              trigger_and_target: "step_guide_fixed_button",
            },
          }}
          maxWidth="100%"
          bottom={isHasBottomFixedButtonGuidePath ? (isBiggerThanMd ? "72px" : "124px") : "72px"}
        />
      )}

      <Menu isOpen={isOpen} onClose={onClose} />
    </>
  );
}
