import { useCallback, useEffect, useRef } from "react";

export function useTimeout() {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const startTimeout = useCallback((callback: () => void, delay: number) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(callback, delay);
  }, []);

  const cancelTimeout = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    return cancelTimeout;
  }, [cancelTimeout]);

  return { startTimeout, cancelTimeout };
}
