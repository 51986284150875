import { CSSObject } from "@emotion/react";
import { CSSProperties } from "react";

export const breakpoints = {
  base: "0px",
  sm: "360px",
  md: "768px",
  lg: "1024px",
  xl: "1200px",
};

export const MQ = {
  base: `@media (min-width: ${breakpoints.base})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`,
};

type BreakpointKeys = keyof typeof breakpoints;

type ResponsiveCSSProperties = {
  [K in keyof CSSProperties]?:
    | CSSProperties[K]
    | { [key in BreakpointKeys]?: CSSProperties[K] };
};

const processProperty = (
  property: keyof CSSProperties,
  value:
    | CSSProperties[keyof CSSProperties]
    | { [key in BreakpointKeys]?: CSSProperties[keyof CSSProperties] }
): {
  base: CSSObject;
  mediaQueries: { breakpoint: BreakpointKeys; styles: CSSObject }[];
} => {
  const baseStyles: any = {};
  const mediaQueries: { breakpoint: BreakpointKeys; styles: CSSObject }[] = [];

  if (typeof value === "object" && value !== null) {
    Object.keys(value).forEach((breakpoint) => {
      if (breakpoint in breakpoints) {
        mediaQueries.push({
          breakpoint: breakpoint as BreakpointKeys,
          styles: {
            [property]: value[breakpoint as BreakpointKeys],
          } as CSSObject,
        });
      }
    });
  } else {
    baseStyles[property] = value;
  }

  return { base: baseStyles, mediaQueries };
};

const combineMediaQueries = (
  mediaQueries: { breakpoint: BreakpointKeys; styles: CSSObject }[]
): CSSObject => {
  const sortedMediaQueries = mediaQueries.sort(
    (a, b) =>
      parseInt(breakpoints[a.breakpoint]) - parseInt(breakpoints[b.breakpoint])
  );

  return sortedMediaQueries.reduce((acc, { breakpoint, styles }) => {
    const mediaQuery = `@media (min-width: ${breakpoints[breakpoint]})`;
    acc[mediaQuery] = {
      ...acc[mediaQuery],
      ...styles,
    };
    return acc;
  }, {} as any);
};

export const responsive = (styles: ResponsiveCSSProperties): CSSObject => {
  const baseStyles: CSSObject = {};
  const mediaQueries: { breakpoint: BreakpointKeys; styles: CSSObject }[] = [];

  Object.keys(styles).forEach((property) => {
    const value = styles[property as keyof CSSProperties];
    const { base, mediaQueries: newMediaQueries } = processProperty(
      property as keyof CSSProperties,
      value
    );
    Object.assign(baseStyles, base);
    mediaQueries.push(...newMediaQueries);
  });

  const mediaQueryStyles = combineMediaQueries(mediaQueries);

  return { ...baseStyles, ...mediaQueryStyles };
};
