import React, { CSSProperties, ReactNode } from "react";
import { customEvent, EventProps } from "../../utils";

export interface ButtonProps {
  children: ReactNode;
  event?: EventProps;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
}

export function Button({
  children,
  event,
  className,
  disabled,
  onClick,
  style,
}: ButtonProps) {
  const handleButtonClick = () => {
    if (event) customEvent(event);
    if (onClick) onClick();
  };

  return (
    <button
      className={className}
      onClick={handleButtonClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
