export const parsePhoneNumber = (input: string) => {
  const tempInput = input;

  if (tempInput.length === 10) {
    return `${tempInput.slice(0, 3)}-${tempInput.slice(3, 6)}-${tempInput.slice(
      6,
      10
    )}`;
  } else {
    return `${tempInput.slice(0, 3)}-${tempInput.slice(3, 7)}-${tempInput.slice(
      7,
      11
    )}`;
  }
};

export const parsingPhoneNumber = (num: string) => {
  if (num.length > 13) {
    return num.slice(0, 13);
  }

  return num
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(-{1,2})$/g, "");
};
