import { Axios } from "../utils/axios";
import { atom, selector } from "recoil";
import { cooKeys, getCookie, setCookie } from "../../goi_common";

export const isLoggedInState = atom<boolean>({
  key: "isLoggedInState",
  default: false,
  effects: [
    ({ setSelf }) => {
      if (process.env.NEXT_PUBLIC_IS_LOGGED_IN_COOKIE)
        setSelf(getCookie(process.env.NEXT_PUBLIC_IS_LOGGED_IN_COOKIE) === "true");
      else setSelf(false);
    },
  ],
});
export const regionCodeState = atom({
  key: "regionCodeState",
  default: "",
});

export const accountState = selector({
  key: "accountState",
  get: async ({ get }) => {
    const isLoggedIn = get(isLoggedInState);
    if (isLoggedIn) {
      try {
        const res = await Axios.get("/users/init/");
        if (res.data) {
          const { memberId } = res.data;
          localStorage.setItem(cooKeys.goi_member_id, `goi-${memberId}`);
          setCookie(cooKeys.goi_member_id, `goi-${memberId}`);

          return res.data;
        }
      } catch {}
    }
    return null;
  },
});

export const hackleUserState = atom({
  key: "hackleUserState",
  default: {},
});
