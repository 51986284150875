import NotFacilitiesModalContent from "@/components/common/Modal/NotFacilitiesModalContent";
import { OverlayModal } from "goi_common";
import QuickCallModalContent from "@/components/common/Modal/QuickCallModalContent";
import { useOverlay } from "@toss/use-overlay";
import { colors } from "goi_common";
import { useRouter } from "next/router";

export function useQuickCallModal(callTo = "1666-9784") {
  const overlay = useOverlay();
  const router = useRouter();
  const isFacilities = router.asPath.includes("facilities");

  const onQuickCallModal = () => {
    overlay.open(({ isOpen, close: onClose }) => {
      return (
        <OverlayModal
          onClose={onClose}
          isOpen={isOpen}
          modalContentStyle={{
            minWidth: "320px",
            background: isFacilities ? colors.black : "white",
          }}
          header={{
            content: isFacilities ? <></> : <QuickCallModalContent.header onClose={onClose} />,
          }}
          overlayModalContent={
            isFacilities ? <NotFacilitiesModalContent /> : <QuickCallModalContent />
          }
          footer={{
            content: isFacilities ? (
              <NotFacilitiesModalContent.footer onClose={onClose} />
            ) : (
              <QuickCallModalContent.footer onClose={onClose} callTo={callTo} />
            ),
            style: { height: "50px !important" },
          }}
        />
      );
    });
  };
  return { onQuickCallModal };
}
