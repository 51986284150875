import { colors } from "../styles";

interface BaseProps {
  index?: number | string;
  checked: boolean;
  onChange?: (e: any) => void;
  label?: string;
  className?: string;
}

const OptionButton = ({
  index,
  checked,
  label,
  onChange,
  className,
}: BaseProps) => {
  return (
    <button
      style={{
        border: `${
          checked
            ? `1px solid ${colors.orange600}`
            : `1px solid ${colors.gray400}`
        }`,
        borderRadius: "6px",
        padding: "8px",
        color: `${checked ? colors.orange700 : colors.gray700}`,
        fontWeight: `${checked ? 600 : 400}`,
        whiteSpace: "pre-line",
      }}
      className={className || "body3"}
      onClick={(e) => onChange?.(e)}
    >
      {label}
    </button>
  );
};

export default OptionButton;
