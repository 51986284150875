import { Box, Collapse, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import {
  call,
  ColorIcon,
  colors,
  customEvent,
  EventTriggerAndTargetType,
  HLine,
  HStack,
  Spacing,
  VStack,
} from "goi_common";
import { useRouter } from "next/router";

import { useState } from "react";
import { OverlayModal, OverlayModalFooterProps, OverlayModalHeaderProps } from "goi_common";

export default function QuickCallModalContent({ category = "" }: { category?: string }) {
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);

  return (
    <VStack width="100%" gap={10}>
      <VStack width="100%" padding="0 24px">
        <HStack width="100%" alignItems="center" gap={8}>
          <ColorIcon name="icon-call-mono" color={colors.gray900} width={16} height={16} />
          <div className="subtitle_21_b gray900">장례 상담</div>
        </HStack>
        <Spacing gap={20} />
        <VStack gap={12} width="100%" className="body_14_m gray800">
          <div className="orange700">
            <b>
              준비가 되지 않으셨더라도,
              <br />
              부담없이 상담하세요
            </b>
          </div>
          <div>막막한 장례 준비. 친절하게 도와드립니다.</div>
          <div>
            염두에 두고 있는 장례식장, <br />
            장례서비스가 없으시더라도
          </div>
          <div>
            고이와 미리 상담하시면, <br />
            경황없는 순간, 당황하지 않으셔도 됩니다.
          </div>
          <div>
            진정성있는, 검증된 장례지도사가
            <br />
            지금 이 순간부터
            <br />
            상주님을 도와드리겠습니다.
          </div>
        </VStack>
        <Spacing gap={16} />
        <HLine height={1} color={colors.gray200} />
        <Spacing gap={16} />
        <HStack
          alignItems="center"
          justifyContent="space-between"
          css={css`
            .transform {
              transform: rotate(180deg);
            }
          `}
        >
          <div className="caption_12_sb gray500">
            맞춤형 장례, 해외 거주자 장례 등<br />
            다양한 장례 서비스를 제공합니다.
          </div>
          <div
            className={`${isOpenAccordion ? "transform" : ""}`}
            onClick={() => {
              customEvent({
                newGtm: {
                  path: "snackbar",
                  trigger_and_target: `quick_mdl_accordion${
                    category ? `-${category}` : ""
                  }-opn_dtl` as EventTriggerAndTargetType["snackbar"],
                },
              });

              setIsOpenAccordion(!isOpenAccordion);
            }}
          >
            <ColorIcon name="downArrow" color={colors.gray400} width={16} height={16} />
          </div>
        </HStack>
      </VStack>

      <VStack>
        <Collapse in={isOpenAccordion}>
          <VStack
            gap={28}
            width="100%"
            background={colors.warmGray50}
            borderRadius={5}
            padding="24px"
          >
            <div className="caption_12_m gray500">
              투명하고 합리적인 장례 시장을 만들고,
              <br />
              고유한 이야기가 가득한 장례 문화를 만들어 나갑니다.
            </div>
            <Item
              number="1"
              title="해외 거주자 국내 장례 상담"
              description={"해외에서 맞닥뜨린 가족, 친지분의 장례\n경황없는 상황부터 도와드립니다."}
            />
            <Item
              number="2"
              title="맞춤형 소규모 장례 상담"
              description={"기존의 장례 형태와 다른 맞춤형 장례를 도와드립니다."}
            />
            <Item
              number="3"
              title="고급 장례"
              description={"대규모 조문객이 예상되는 경우, 특별한 VIP 장례를 도와드립니다."}
            />
            <Item
              number="4"
              title="B2B 장례"
              description={
                "기업 임직원 복리후생으로 올인원 조사 (장례) 관리 서비스를 제공해 드립니다."
              }
            />
          </VStack>
        </Collapse>
        <Box padding="20px">
          <Box
            width="100%"
            padding="12px 14px"
            background={colors.gray50}
            borderRadius="5px"
            className="caption_12_m gray600"
          >
            전화가 불가능한 상황이라면{" "}
            <a href="http://pf.kakao.com/_xgFDaK/chat">
              <b>
                <u>여기</u>
              </b>
            </a>
            로 문의해주세요.
          </Box>
        </Box>
      </VStack>
    </VStack>
  );
}

const Item = ({
  number,
  title,
  description,
}: {
  number: string;
  title: string;
  description: string;
}) => {
  return (
    <HStack gap={12}>
      <Flex
        borderRadius="50%"
        border={`1px solid ${colors.orange550}`}
        backgroundColor={colors.orange100}
        width="36px"
        minWidth="36px"
        height="36px"
        justifyContent="center"
        alignItems="center"
        className="subtitle_16_b orange650"
      >
        {number}
      </Flex>
      <VStack>
        <div className="subtitle_14_sb">{title}</div>
        <Box className="caption_12_m gray700" whiteSpace="pre-line">
          {description}
        </Box>
      </VStack>
    </HStack>
  );
};

function QuickCallModalContentHeader({
  onClose,
  category = "",
}: {
  onClose: () => void;
  category?: string;
}) {
  const headerProps: OverlayModalHeaderProps = {
    item: {
      onClick: () => {
        onClose();
      },
      closeEvent: {
        newGtm: {
          path: "snackbar",
          trigger_and_target: `quickcall_mdl${
            category ? `-${category}` : ""
          }-close` as EventTriggerAndTargetType["snackbar"],
        },
      },
    },
  };
  return <OverlayModal.Header.CloseBtnType props={headerProps} />;
}

QuickCallModalContent.header = QuickCallModalContentHeader;

function QuickCallModalContentFooter({
  onClose,
  callOnly = false,
  category = "",
  callTo = "1666-9784",
}: {
  onClose: () => void;
  callOnly?: boolean;
  category?: string;
  callTo?: string;
}) {
  const router = useRouter();

  const callOnlyFooterProps: OverlayModalFooterProps = {
    item: [
      {
        text: (
          <HStack width="100%" alignItems="center" justifyContent="center" gap={8}>
            <ColorIcon name="icon-call-mono" color={colors.white} width={16} height={16} />
            <div>24시간 상담 전화 걸기</div>
          </HStack>
        ),
        onClick: () => {
          call(callTo);
        },
        event: {
          newGtm: {
            path: "snackbar",
            trigger_and_target: `quick_mdl_ftr${
              category ? `-${category}` : ""
            }-one-actn_call` as EventTriggerAndTargetType["snackbar"],
          },
        },
      },
    ],
  };

  const footerProps: OverlayModalFooterProps = {
    item: [
      {
        text: <>후기 확인</>,
        onClick: () => {
          router.push("/review");
          onClose();
        },
        width: "50%",
        event: {
          newGtm: {
            path: "snackbar",
            trigger_and_target: "quick_mdl_ftr_lft-uv_review",
          },
        },
      },
      {
        text: (
          <HStack width="100%" alignItems="center" justifyContent="center" gap={8}>
            <ColorIcon name="icon-call-mono" color={colors.white} width={16} height={16} />
            <div>24시간 상담</div>
          </HStack>
        ),
        onClick: () => {
          call(callTo);
        },
        event: {
          newGtm: {
            path: "snackbar",
            trigger_and_target: "quick_mdl_ftr_rght-actn_call",
          },
        },
      },
    ],
  };

  if (callOnly) return <OverlayModal.Footer.OneBtnType props={callOnlyFooterProps} />;
  return <OverlayModal.Footer.TwinBtnType props={footerProps} />;
}

QuickCallModalContent.footer = QuickCallModalContentFooter;
