import { css } from "@emotion/react";
import React from "react";
import { getCdnImageSrc } from "../utils";

export function CircleImage({ src, radius }: { src?: string; radius: number }) {
  return (
    <div
      css={css`
        width: ${radius}px;
        height: ${radius}px;
        border-radius: ${radius}px;
        background-size: cover;
        background-position: center center;
        background-image: url(${src ||
        getCdnImageSrc("platform/default-profile.png")});
      `}
    ></div>
  );
}
