import { ParsedUrlQuery, stringify } from "querystring";

export function getFullQueryParamsString(queryParams?: ParsedUrlQuery) {
  const queryParamsString = stringify(queryParams);
  const fullQueryParamsStirng = queryParamsString
    ? `?${queryParamsString}`
    : "";

  return fullQueryParamsStirng;
}
