import axios from "axios";

export const Axios = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

Axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await Axios.post("/users/refresh/");
        return Axios(originalRequest);
      } catch {}
    }

    return Promise.reject(err);
  },
);
