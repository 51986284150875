import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useRef,
  useState,
} from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { VStack } from "./VStack";

export default function LazyLoad({
  children,
  hasCustomState,
  customVisibleState,
  setCustomVisibleState,
  top = -1000,
  width,
  alignItems,
  justifyContent,
  height,
}: {
  children: ReactNode;
  hasCustomState?: boolean;
  customVisibleState?: boolean;
  setCustomVisibleState?: Dispatch<SetStateAction<boolean>>;
  top?: number;
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  height?: string;
}) {
  const [visible, setVisible] = useState(false);
  const lazyLoadRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    root: null,
    target: lazyLoadRef,
    rootMargin: "100px",
    onIntersect: () => {
      if (hasCustomState) {
        if (setCustomVisibleState) setCustomVisibleState(true);
      } else {
        setVisible(true);
      }
    },
  });

  return (
    <VStack
      position="relative"
      alignItems={alignItems}
      justifyContent={justifyContent}
      width={width}
      height={height}
    >
      <div
        css={{ position: "absolute", width: "100%", height: "100px", top: top }}
        ref={lazyLoadRef}
      />
      {hasCustomState ? customVisibleState && children : visible && children}
    </VStack>
  );
}
