export const colors = {
  black: "#000000",
  white: "#FEFEFF",
  goreen: "#2B8A3E",
  goreen200: "#8CE99A",

  yellow: "#FFED4B",
  kakao: "#FEE500",
  kakaoText: "#191919",
  reviewYellow: "#FFC700",

  gray50: "#f5f5f5",
  gray100: "#e9e9e9",
  gray200: "#dedede",
  gray300: "#cccccc",
  gray400: "#b9b9b9",
  gray500: "#8f8f8f",
  gray600: "#686868",
  gray700: "#535353",
  gray800: "#3d3d3d",
  gray900: "#252525",

  green50: "#EBFBEE",
  green100: "#D3F9D8",
  green150: "#B2F2BB",
  green200: "#8CE99A",
  green250: "#69DB7C",
  green300: "#51CF66",
  green350: "#40C057",
  green400: "#37B24D",
  green450: "#2F9E44",
  green500: "#2B8A3E",

  warmGray50: "#FAFAFA",
  warmGray100: "#F6F4F4",
  warmGray150: "#F1EFEF",
  warmGray200: "#EDEAE9",
  warmGray250: "#E8E5E4",
  warmGray300: "#E3DFDF",
  warmGray350: "#DFDAD9",
  warmGray400: "#DAD5D4",
  warmGray450: "#D6CFCE",
  warmGray500: "#D1CAC9",
  warmGray550: "#BEB4B3",
  warmGray600: "#AB9F9D",
  warmGray650: "#988987",
  warmGray700: "#857471",
  warmGray750: "#6F605E",
  warmGray800: "#594D4B",
  warmGray850: "#423A39",
  warmGray900: "#2C2726",
  warmGray950: "#171313",

  brown50: "#F5EEEA",
  brown100: "#EBDCD6",
  brown150: "#E1CBC1",
  brown200: "#D7B9AC",
  brown250: "#CDA897",
  brown300: "#C39783",
  brown350: "#B9856E",
  brown400: "#AF7459",
  brown450: "#9E654C",
  brown500: "#895842",
  brown550: "#7B4F3B",
  brown600: "#6E4635",
  brown650: "#603E2E",
  brown700: "#523528",
  brown750: "#452C21",
  brown800: "#37231A",
  brown850: "#291A14",
  brown900: "#1B120D",
  brown950: "#0E0907",

  blue50: "#F8FAFD",
  blue100: "#F1F5FB",
  blue150: "#EAEFF8",
  blue200: "#E3EAF6",
  blue250: "#DBE5F4",
  blue300: "#D4E0F2",
  blue350: "#CDDBF0",
  blue400: "#C6D5ED",
  blue450: "#BFD0EB",
  blue500: "#B8CBE9",
  blue550: "#98B4DF",
  blue600: "#789CD5",
  blue650: "#5885CB",
  blue700: "#3B6EBF",
  blue750: "#315C9F",
  blue800: "#274A7F",
  blue850: "#1E3760",
  blue900: "#142540",
  blue950: "#0A1220",

  orange50: "#FEFAF6",
  orange100: "#FEF5EE",
  orange150: "#FDF1E5",
  orange200: "#FDECDD",
  orange250: "#FCE7D4",
  orange300: "#FBE2CB",
  orange350: "#FBDDC3",
  orange400: "#FAD9BA",
  orange450: "#FAD4B2",
  orange500: "#F9CFA9",
  orange550: "#F6B982",
  orange600: "#F4A35B",
  orange650: "#F18334",
  orange700: "#EA7810",
  orange750: "#C3640E",
  orange800: "#9C500B",
  orange850: "#753C08",
  orange900: "#4E2805",
  orange950: "#271403",

  danger: "#E2452B",
  warning: "#FFBD14",
  success: "#2CBA00",

  overlay: "#00000080",
};

const spacings = {
  xxs: "4px",
  xs: "8px",
  sm: "16px",
  md: "20px",
  lg: "40px",
  xlg: "60px",
};

export const theme = {
  colors,
  spacings,
};

type ThemeType = typeof theme;
export type { ThemeType };
