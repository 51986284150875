import {
  colors,
  customEvent,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  TwinButton,
} from "goi_common";
import React from "react";
import { FooterItemProps, OverlayModalFooterProps } from ".";

const footerOnClick = (prop: FooterItemProps) => {
  if (prop.event) customEvent(prop.event);
  prop.onClick();
};

export function OverlayModalFooterOneBtnType({
  props,
}: {
  props: OverlayModalFooterProps;
}) {
  const { item } = props;

  return (
    <LargeButton
      buttonType={
        item[0].disable
          ? LARGEL_BUTTON_TYPE.DISABLED
          : item[0].oneBtnType || LARGEL_BUTTON_TYPE.ORANGE
      }
      onClick={() => {
        footerOnClick(item[0]);
      }}
      css={{
        borderRadius: "0px",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
    >
      {item[0].text}
    </LargeButton>
  );
}

export function OverlayModalFooterTwinBtnType({
  props,
}: {
  props: OverlayModalFooterProps;
}) {
  const { item } = props;
  return (
    <TwinButton
      firstButton={{
        text: item[0].text,
        width: item[0].width,
        background: item[0].background || colors.black,
        onClick: () => {
          footerOnClick(item[0]);
        },
      }}
      secondButton={{
        text: item[1].text,
        background: item[1].background || colors.orange650,
        onClick: () => {
          footerOnClick(item[1]);
        },
      }}
      height="60px"
    />
  );
}
