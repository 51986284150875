import React, { ReactNode } from "react";
import { customEvent, EventProps } from "../utils/customEvent";

export function LoggingClick({
  children,
  event,
}: {
  children: ReactNode;
  event: EventProps;
}) {
  return <div onClick={() => customEvent(event)}>{children}</div>;
}
