import { css } from "@emotion/react";
import React, { HTMLAttributes } from "react";
import { icon } from "../../constants/icon";
import { CSSPixelValue } from "../../types/CSSPixelValue";
import { forwardRefWithAs } from "../../utils";
import { coerceCssPixelValue } from "../../utils/coerceCssPixelValue";

interface BaseProps {
  name: keyof typeof icon;
  color: string;
  size?: CSSPixelValue;
  width?: CSSPixelValue;
  height?: CSSPixelValue;
}

type Props = BaseProps & Omit<HTMLAttributes<HTMLDivElement>, keyof BaseProps>;

export const ColorIcon = forwardRefWithAs<Props, "div">(function ColorIcon(
  {
    as: Component = "div",
    name,
    size,
    width = size,
    height = size,
    style,
    color,
    ...rest
  },
  ref
) {
  return (
    <Component
      style={{
        ...style,
        width: coerceCssPixelValue(width),
        height: coerceCssPixelValue(height),
        flexShrink: 0,
        pointerEvents: "none",
      }}
      ref={ref}
      css={css`
        svg {
          width: 100%;
          height: 100%;
        }

        & [fill] {
          &:not([fill="none"]) {
            fill: ${color};
          }
        }

        & [stroke] {
          &:not([stroke="none"]) {
            stroke: ${color};
          }
        }
      `}
      {...rest}
    >
      {icon[name]}
    </Component>
  );
});
