interface SignalDotProps {
  color: string;
  size: number;
}

export function SignalDot({ color, size }: SignalDotProps) {
  return (
    <div
      style={{
        background: color,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: "50%",
      }}
    />
  );
}
