import { HackleClient } from "./hackle";
import {
  DableEventName,
  HackleEventName,
  NaverEventName,
  PixelEventName,
  GTMEventName,
  CommonEventProps,
} from "../types";

declare global {
  interface Window {
    dataLayer: any[];
    dablena: any;
    fbq: any;
    wcs_add: any;
    _nasa: any;
    wcs: any;
    wcs_do: any;
    hackleClient: any;
    hackleUser: any;
    kakaoPixel: any;
    karrotPixel: any;
  }
}

export type HackleEventProp = {
  track?: any;
  key: HackleEventName;
  value?: number;
  properties?: { [key: string]: boolean | string | number };
};

export type NewGTMEventProp = {
  key: HackleEventName;
  properties?: { [key: string]: boolean | string | number };
};

export type EventProps =
  | GTMEventName
  | {
      gtm?: GTMEventName;
      newGtm?: CommonEventProps;
      hackle?: HackleEventProp;
      pixel?: PixelEventName;
      dable?: DableEventName;
      naver?: NaverEventName;
      triggerHackle?: boolean;
    };

export const customEvent = (event: EventProps) => {
  if (typeof event === "string") GTMCustomEvent(event);
  else {
    if (event.gtm) GTMCustomEvent(event.gtm);

    if (event.newGtm) {
      NewGTMCustomEvent(event.newGtm);

      if (!event.hackle) {
        TriggerHackleCustomEvent(event.newGtm);
      }
    }

    if (event.hackle) HackleCustomEvent(event.hackle);
    if (event.pixel) pixelCustomEvent(event.pixel);
    if (event.dable) dableCustomEvent(event.dable);
    if (event.naver) naverCustomEvent(event.naver);
  }
};

export const GTMPageView = (url: string) => {
  const goiMemberId = localStorage.getItem("goi-member-id");
  if (window.dataLayer)
    window.dataLayer.push({
      event: "pageview",
      page: url,
      user_id: goiMemberId ? goiMemberId.replace("goi-", "") : undefined,
    });
};

export const GTMCustomEvent = (event: GTMEventName) => {
  if (window.dataLayer) window.dataLayer.push({ event });
};

export const NewGTMCustomEvent = (event: CommonEventProps) => {
  let gtmEventObject;

  // 이벤트를 가공해야 합니다.
  if (event.action) {
    gtmEventObject = { event: event.action };
  } else {
    const eventName = `click-${event.path}-${event.trigger_and_target}`;
    gtmEventObject = { event: eventName, text: event.text };
  }

  if (window.dataLayer) {
    const goiMemberId = localStorage.getItem("goi-member-id");
    window.dataLayer.push({ ...gtmEventObject, user_id: goiMemberId });
  }
};

export const TriggerHackleCustomEvent = (event: CommonEventProps) => {
  let hackleEventObject;

  if (event.action) {
    hackleEventObject = {
      key: event.action,
    };
  } else {
    const eventName = `click-${event.path}-${event.trigger_and_target}`;
    hackleEventObject = {
      key: eventName,
      properties: { text: event.text },
    };
  }

  HackleClient.track(hackleEventObject, HackleClient.getUser());
};

export const HackleCustomEvent = (event: HackleEventProp) => {
  if (event.track) {
    event.track(event);
  } else if (HackleClient) {
    HackleClient.track(event, HackleClient.getUser());
  }
};

export const pixelCustomEvent = (event: PixelEventName) => {
  if (window.fbq) window.fbq("track", event);
};

export const dableCustomEvent = (event: DableEventName) => {
  if (window.dablena) window.dablena("track", event);
};

export const naverCustomEvent = (event: NaverEventName) => {
  window._nasa = {};
  if (window.wcs) window._nasa["cnv"] = window.wcs.cnv(event, "1");
};

export const kakaoPixel = () => {
  if (window.kakaoPixel && process.env.NEXT_PUBLIC_KAKAO_PIXEL_ID) {
    return window.kakaoPixel(process.env.NEXT_PUBLIC_KAKAO_PIXEL_ID);
  }
  return null;
};

export const daangnPixelTrack = (eventName: string) => {
  if (window.karrotPixel) {
    return window.karrotPixel.track(eventName);
  }
  return null;
};
