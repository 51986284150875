import {
  TableContainer,
  Table,
  Th,
  Td,
  Tr,
  Tbody,
  Box,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { NextLink, colors, EventProps, customEvent } from "goi_common";
import { ParsedUrlQuery } from "querystring";
import { getFullQueryParamsString } from "../../utils/searchParams";

interface ObjectTableStyleProps {
  minWidth?: number;
  maxWidth?: number;
  keys: {
    text: string;
    value: string | string[];
    renderCell?: (val: any) => ReactNode;
    textAlign?: "center" | "left";
    maxWidth?: string | number;
  }[];
  items: Array<any>;
  boardPath?: string;
  eventName?: EventProps;
  isHeaderHidden?: boolean;
  expandTableRow?: (index: number, val?: any) => ReactNode;
  queryParams?: ParsedUrlQuery;
}
type ObjectTableProps = ObjectTableStyleProps;

function BoardObjectTable({
  minWidth,
  maxWidth,
  keys,
  items,
  boardPath,
  eventName,
  expandTableRow,
  isHeaderHidden,
  queryParams,
}: ObjectTableProps) {
  function getRenderedCell(
    value: any,
    renderCell?: (value: any) => React.ReactNode
  ) {
    if (renderCell) {
      return renderCell(value);
    }
    if (
      !renderCell &&
      (typeof value === "object" || typeof value === "boolean") &&
      value !== null
    ) {
      return JSON.stringify(value);
    }
    return value;
  }

  return (
    <TableContainer>
      <Table
        variant="simple"
        css={{
          minWidth,
          maxWidth,
          border: "none",
          color: colors.gray900,
        }}
      >
        <Tbody>
          {!isHeaderHidden && (
            <Tr>
              {keys.map((key) => {
                return (
                  <Box
                    as={Th}
                    width={key.maxWidth}
                    key={`row_${key.text}`}
                    whiteSpace="pre"
                    padding="12px"
                    textAlign={key.textAlign || "center"}
                    background={colors.gray50}
                    color={colors.gray900}
                    className="subtitle_14_b"
                  >
                    {key.text}
                  </Box>
                );
              })}
            </Tr>
          )}
          {items.map((item, i) => {
            return (
              item && (
                <>
                  <Tr key={`tr_${i}`}>
                    {keys.map((key) => {
                      let renderValue;
                      if (typeof key.value === "string") {
                        renderValue = key.value;
                      } else {
                        if (item[key.value[0]]) {
                          renderValue = key.value[0];
                        } else {
                          renderValue = key.value[1];
                        }
                      }
                      return (
                        <Td
                          key={`td_${key.text}`}
                          width={key.maxWidth || "none"}
                          wordBreak="break-all"
                          verticalAlign="top"
                          padding="8px 10px"
                          borderBottom={
                            expandTableRow
                              ? "none"
                              : `1px solid ${colors.gray100}`
                          }
                          textAlign={key.textAlign || "center"}
                        >
                          <NextLink
                            key={`row_v_${i}`}
                            href={`/${boardPath}/${
                              item.id
                            }${getFullQueryParamsString(queryParams)}`}
                            onClick={() => {
                              if (eventName) {
                                customEvent(eventName);
                              }
                            }}
                            css={{ width: "100%" }}
                            className="body_14_m gray900"
                          >
                            {getRenderedCell(item[renderValue], key.renderCell)}
                          </NextLink>
                        </Td>
                      );
                    })}
                  </Tr>
                  {expandTableRow && (
                    <Tr maxWidth="100%">
                      <Td
                        padding="2px 12px 12px"
                        colSpan={4}
                        borderBottom={`1px solid ${colors.gray100}`}
                      >
                        {expandTableRow(i, item)}
                      </Td>
                    </Tr>
                  )}
                </>
              )
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
export { BoardObjectTable };
