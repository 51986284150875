import React, { HTMLAttributes } from "react";

import { CSSPixelValue } from "../types/CSSPixelValue";
import { coerceCssPixelValue } from "../utils/coerceCssPixelValue";

interface LineProps {
  color?: string;
  width?: CSSPixelValue;
  height?: CSSPixelValue;
  margin?: CSSPixelValue;
}

type Props = LineProps & Omit<HTMLAttributes<HTMLDivElement>, keyof LineProps>;

export const HLine = ({
  color = "black",
  width = "100%",
  height = 1,
  margin,
}: Props) => {
  return (
    <div
      style={{
        background: color,
        width: coerceCssPixelValue(width),
        height: coerceCssPixelValue(height),
        minHeight: coerceCssPixelValue(height),
        margin: coerceCssPixelValue(margin),
      }}
    >
      &nbsp;
    </div>
  );
};

export const VLine = ({
  color = "black",
  width = 1,
  height = "100%",
  margin,
  ...rest
}: Props) => {
  return (
    <div
      style={{
        background: color,
        width: coerceCssPixelValue(width),
        height: coerceCssPixelValue(height),
        margin: coerceCssPixelValue(margin),
      }}
      {...rest}
    >
      &nbsp;
    </div>
  );
};
