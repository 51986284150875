import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors, Hamburger, NextLink, Spacing, HStack, GoiLogo } from "goi_common";

import { useState } from "react";
import { GuidebookSummaryDrawer } from "../Guidebook/GuidebookSummaryDrawer";

function GuidebookSummaryHeader() {
  const [open, setOpen] = useState(false);

  return (
    <HeaderRoot
      css={css`
        background-color: white;
        transition: background-color 0.2s;
      `}
      style={{ backgroundColor: "white", maxWidth: "var(--global-max-width)" }}
    >
      <HStack width="100%" maxWidth="1200px" justifyContent="space-between" alignItems="center">
        <>
          <NextLink href="/">
            <HStack alignItems="center">
              <GoiLogo color="black" />
              <Spacing gap={12} orientation="horizontal" />
              <div className="button" style={{ color: "black" }}>
                서비스 이용하기
              </div>
            </HStack>
          </NextLink>
          <button
            css={css`
              background: ${colors.black};
              border-radius: 30px;
              padding: 12px 16px;
              color: white;
            `}
            onClick={() => setOpen(true)}
          >
            <HStack gap="6px" alignItems="center" justifyContent="center">
              <Hamburger color={colors.white} size={16} />
              <Box className="button white">목차</Box>
            </HStack>
          </button>
        </>
        <GuidebookSummaryDrawer open={open} onClose={() => setOpen(false)} />
      </HStack>
    </HeaderRoot>
  );
}

const HeaderRoot = styled.div`
  justify-content: center;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 1010;
  width: 100%;
  height: 60px;
  position: sticky;
`;

export default GuidebookSummaryHeader;
