import Link from "next/link";
import React, { ReactNode } from "react";
import { customEvent, EventProps } from "../utils";

interface NextLinkProps {
  id?: string;
  children: ReactNode;
  href: string;
  event?: EventProps;
  className?: string;
  name?: string;
  onClick?: () => void;
}

export function NextLink({
  id,
  children,
  href,
  event,
  className,
  name,
  onClick,
}: NextLinkProps) {
  const handleNextLinkClick = () => {
    if (event) customEvent(event);
    if (onClick) onClick();
  };

  return (
    <Link
      href={href}
      id={id}
      target={
        href.startsWith("https://") || href.startsWith("http://")
          ? "_blank"
          : undefined
      }
      itemProp="item"
      rel="noopener noreferrer"
      onClick={handleNextLinkClick}
      className={className}
      style={{ outline: "none", textDecoration: "none" }}
    >
      {children}
    </Link>
  );
}
