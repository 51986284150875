import { Box } from "@chakra-ui/react";
import {
  ColorIcon,
  HStack,
  LargeButton,
  LARGEL_BUTTON_TYPE,
  VStack,
} from "../../..";
import { customEvent } from "../../../../utils";
import React from "react";

export function RevisitModalContent({ onClose }: { onClose: () => void }) {
  const onClickModalClose = () => {
    customEvent({
      newGtm: {
        action: "revisit_modal_close",
      },
    });
    onClose();
  };

  const onClickModalCTA = () => {
    customEvent({
      newGtm: {
        action: "revisit_modal_cta",
      },
    });
    window.open("http://pf.kakao.com/_xgFDaK/chat");
    onClose();
  };
  return (
    <VStack padding="20px">
      <HStack justifyContent="flex-end">
        <Box onClick={onClickModalClose}>
          <ColorIcon name="icon-x-mono" color="" size={20} />
        </Box>
      </HStack>
      <VStack gap="16px">
        <Box className="subtitle_21_b">장례를 준비 중이신가요?</Box>
        <Box
          className="body_16_r"
          css={{
            wordBreak: "keep-all",
          }}
        >
          상주님께서 가장 합리적인 장례를 치룰 수 있도록 장례 상담을
          도와드립니다.
          <br /> 채팅으로 부담없이 장례 상담을 받아보세요.
        </Box>
        <LargeButton
          className="subtitle_16_sb"
          buttonType={LARGEL_BUTTON_TYPE.ORANGE}
          onClick={onClickModalCTA}
        >
          채팅 상담 받기
        </LargeButton>
      </VStack>
    </VStack>
  );
}
