import { ColorIcon, colors, customEvent, HStack } from "goi_common";
import React from "react";
import { OverlayModalHeaderProps } from ".";

export function OverlayModalHeaderCloseBtnType({
  props,
}: {
  props: OverlayModalHeaderProps;
}) {
  const { title, closeEvent, onClick } = props.item;

  return (
    <HStack
      width="100%"
      justifyContent={title ? "space-between" : "end"}
      padding="24px 24px 0px"
      alignItems="center"
      style={{
        wordBreak: "keep-all",
      }}
    >
      {title && <div>{title}</div>}
      <div
        onClick={() => {
          if (closeEvent) customEvent(closeEvent);
          if (onClick) onClick();
        }}
      >
        <ColorIcon name="close" width={24} height={24} color={colors.gray500} />
      </div>
    </HStack>
  );
}

export function OverlayModalHeaderCloseLargeBtnType({
  props,
}: {
  props: OverlayModalHeaderProps;
}) {
  const { title, closeEvent, onClick } = props.item;

  return (
    <HStack
      width="100%"
      justifyContent={title ? "space-between" : "end"}
      padding="20px 20px 0px"
      alignItems="center"
    >
      {title && <div>{title}</div>}
      <div
        onClick={() => {
          if (closeEvent) customEvent(closeEvent);
          if (onClick) onClick();
        }}
      >
        <ColorIcon name="close" width={32} height={32} color={colors.gray900} />
      </div>
    </HStack>
  );
}
