import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { flexCentering } from "../../styles";
import { Button, ButtonProps } from "./Button";
import React from "react";

export enum ROUNDED_BUTTON_TYPE {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WHITE = "white",
  OUTLINE = "outline",
  ORANGE = "orange",
}

interface RoundedButtonProps extends ButtonProps {
  buttonType: ROUNDED_BUTTON_TYPE;
}

function RoundedButton({
  children,
  buttonType,
  onClick,
  event,
}: RoundedButtonProps) {
  return (
    <RoundedButtonRoot
      className="button2"
      buttonType={buttonType}
      onClick={onClick}
      event={event}
    >
      {children}
    </RoundedButtonRoot>
  );
}

const buttonTypeToStyle = (buttonType: ROUNDED_BUTTON_TYPE) => {
  switch (buttonType) {
    case ROUNDED_BUTTON_TYPE.PRIMARY:
      return css`
        padding: 10px 18px;
        background: var(--black);
        color: var(--white);
      `;
    case ROUNDED_BUTTON_TYPE.SECONDARY:
      return css`
        padding: 9px 17px;
        border: 1px solid var(--warmGray900);
        color: var(--warmGray900);
      `;
    case ROUNDED_BUTTON_TYPE.WHITE:
      return css`
        padding: 10px 18px;
        background: var(--white);
        color: var(--black);
      `;
    case ROUNDED_BUTTON_TYPE.OUTLINE:
      return css`
        padding: 9px 17px;
        border: 1px solid var(--blue50);
        color: var(--blue50);
      `;
    case ROUNDED_BUTTON_TYPE.ORANGE:
      return css`
        padding: 9px 17px;
        background: var(--orange100);
        color: var(--orange700);
      `;

    default:
      break;
  }
};

const RoundedButtonRoot = styled(Button)<{ buttonType: ROUNDED_BUTTON_TYPE }>`
  ${flexCentering("row")}
  width: fit-content;
  border-radius: 18px;
  line-height: 18px;
  box-sizing: border-box;

  ${({ buttonType }) => buttonTypeToStyle(buttonType)}
`;

export { RoundedButton };
