import { extendTheme } from "@chakra-ui/react";
import { theme } from "../theme/theme";
import buttonBase from "./buttonBase";

const chakraDefaultTheme = {
  colors: {
    ...theme.colors,
  },
  fonts: {
    body: "Pretendard Variable, Apple SD Gothic Neo",
  },
  // https://chakra-ui.com/docs/theming/component-style#styling-single-part-components
  components: {
    Button: buttonBase,
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};

export const chakraTheme = extendTheme(chakraDefaultTheme);
