import { ReactNode } from "react";
import { HStack } from "../HStack";
import { Button } from "./Button";

interface ButtonProps {
  width?: string;
  borderRadius?: string;
  background: string;
  onClick?: () => void;
  text: ReactNode;
}

interface TwinButtonProps {
  height: string;
  firstButton: ButtonProps;
  secondButton: ButtonProps;
}

export function TwinButton({
  height,
  firstButton,
  secondButton,
}: TwinButtonProps) {
  return (
    <HStack
      width="100%"
      height={height}
      style={{
        whiteSpace: "pre-line",
      }}
    >
      <Button
        style={{
          color: "white",
          width: firstButton.width,
          height: "100%",
          backgroundColor: firstButton.background,
          borderRadius: firstButton.borderRadius || "0",
        }}
        onClick={firstButton.onClick}
        className="button"
      >
        {firstButton.text}
      </Button>
      <Button
        style={{
          color: "white",
          width: `calc(100% - ${firstButton.width})`,
          height: "100%",
          backgroundColor: secondButton.background,

          borderRadius: secondButton.borderRadius || "0",
        }}
        className="button"
        onClick={secondButton.onClick}
      >
        {secondButton.text}
      </Button>
    </HStack>
  );
}
