import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (
  name: string,
  value: string,
  maxAge = 31536000,
  domain?: string
) => {
  return cookies.set(name, value, { path: "/", maxAge: maxAge, domain });
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const removeCookie = (name: string) => {
  return cookies.remove(name, { path: "/" });
};
