import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Spacing = styled.div<{
  gap: number;
  orientation?: "vertical" | "horizontal";
}>`
  ${({ gap, orientation }) => {
    switch (orientation) {
      case "vertical":
        return css`
          margin-top: ${gap}px;
        `;
      case "horizontal":
        return css`
          margin-left: ${gap}px;
        `;
    }
  }}
`;

Spacing.defaultProps = {
  orientation: "vertical",
};
