// @ts-nocheck
import CryptoJS from "crypto-js";

export function aes256Encrypt(plainText: string) {
  // 16바이트 IV 생성 (여기서는 임의의 IV를 생성, 실제 사용시 고정된 IV 사용 또는 동적으로 생성)
  const iv = CryptoJS.lib.WordArray.random(16);

  // 암호화
  const encrypted = CryptoJS.AES.encrypt(
    plainText,
    CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_AES_KEY as string),
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  // IV와 암호문 결합
  const combined = iv.concat(encrypted.ciphertext);

  // base64로 인코딩하여 반환
  return CryptoJS.enc.Base64.stringify(combined);
}

export function aes256Decrypt(encodedText: string) {
  // base64 디코딩
  const decodedData = CryptoJS.enc.Base64.parse(encodedText);

  // IV와 암호문 추출
  const iv = CryptoJS.lib.WordArray.create(decodedData.words.slice(0, 4));
  const cipherText = CryptoJS.lib.WordArray.create(decodedData.words.slice(4));

  // 복호화
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: cipherText },
    CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_AES_KEY as string),
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  // UTF-8 문자열로 디코딩하여 반환

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export default aes256Decrypt;
