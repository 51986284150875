import { serviceDialogState } from "@/atoms/previewDialogState";
import { useServiceDialog } from "@/hooks/useServiceDialog";
import { useRecoilValue } from "recoil";
import ServiceModal from "./ServiceModal";

export default function PreviewServiceDialogRoot() {
  const serviceDialogProp = useRecoilValue(serviceDialogState);
  const { onClose } = useServiceDialog();
  return <ServiceModal isOpen={serviceDialogProp.open} onClose={onClose} {...serviceDialogProp} />;
}
