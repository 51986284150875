import { getViewportSize } from "@toss/utils";
import { useEffect, useState } from "react";

export default function useGetViewPortResize() {
  const [viewportSize, setViewportSize] = useState(getViewportSize().width);

  let timer: any;
  const handleResize = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const view = getViewportSize().width;
      setViewportSize(view);
    }, 30);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { viewportSize };
}
