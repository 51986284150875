import { Box, Grid } from "@chakra-ui/react";
import {
  colors,
  getCdnImageSrc,
  Image,
  useResponsive,
  VStack,
} from "goi_common";
import React from "react";

export default function CollaborationSection() {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={colors.gray900}
      padding={isBiggerThanLg ? "99px 24px 88px" : "64px 24px 72px"}
    >
      <VStack maxWidth={`${500}px`} width="100%" gap="40px">
        <Box
          className={isBiggerThanLg ? "head_32_b white" : "subtitle_21_b white"}
        >
          고이는 중소벤처기업부, 국민은행, 서울대학교 등<br />
          신뢰도 높은 기관들과 함께 {isBiggerThanLg || <br />}
          상조 서비스를 혁신하고 있습니다.
        </Box>
        <VStack width="100%" alignItems="center">
          <Grid
            gridRowGap={isBiggerThanLg ? "20px" : "16px"}
            gridColumnGap={isBiggerThanLg ? "20px" : "4px"}
            gridTemplateColumns={
              isBiggerThanLg ? "1fr 1fr 1fr 1fr 1fr 1fr" : "2fr 2fr 2fr"
            }
            width="100%"
          >
            <GridItem id={1} text="중소벤처기업부" />
            <GridItem id={2} text="교보생명" />
            <GridItem id={3} text="국민은행" />
            <GridItem id={4} text="신용보증기금" />
            <GridItem id={5} text="카카오벤처스" />
            <GridItem id={6} text="서울대학교" />
          </Grid>
        </VStack>
      </VStack>
    </VStack>
  );
}

function GridItem({ id, text }: { id: number; text: string }) {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack alignItems="center" gap="8px" width="100%">
      <Box
        borderRadius={isBiggerThanLg ? "48px" : "100px"}
        overflow="hidden"
        width={isBiggerThanLg ? "100%" : "100px"}
      >
        <Image
          src={getCdnImageSrc(
            isBiggerThanLg
              ? `direct/home/collabo-${id}.png`
              : `direct/home/collabo-m-${id}.png`
          )}
          alt=""
        />
      </Box>
      <Box
        fontSize={isBiggerThanLg ? 20 : 14}
        fontWeight={500}
        className="gray300"
      >
        {text}
      </Box>
    </VStack>
  );
}
