import FileRequestModal from "@/containers/Parlor/ParlorDetailContainer/components/Modal/FileRequestModal";
import { Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useOverlay } from "@toss/use-overlay";
import {
  ColorIcon,
  colors,
  customEvent,
  EventProps,
  getCdnImageSrc,
  HStack,
  Image,
} from "goi_common";
import { useRouter } from "next/router";
import { ReactNode, useMemo } from "react";

function StepGuideFooter({
  type = "DEFAULT",
  text = (
    <>
      장례가 처음이신가요? 합리적인 장례,
      <br />
      장례식장과 상담하기 전 반드시 알아보세요.
    </>
  ),
  event = {
    newGtm: {
      path: "parlor_detail",
      trigger_and_target: "step_guide_fixed_button",
    },
  },
  src = "/step-guide/initiation",
  size = "44px",
  maxWidth = "480px",
  bottom = "80px",
  right = "",
  iconMarginLeft,
  transform = "",
}: {
  type?: string;
  text?: ReactNode;
  event?: EventProps;
  src?: string;
  size?: string;
  maxWidth?: string;
  bottom?: string;
  right?: string;
  iconMarginLeft?: string;
  transform?: string;
}) {
  const overlay = useOverlay();
  const router = useRouter();
  const typeToStyle = useMemo(() => {
    if (type === "DEFAULT") {
      return {
        background: colors.white,
        color: colors.gray800,
      };
    } else if (type === "DARK") {
      return {
        background: colors.gray800,
        color: colors.white,
      };
    } else {
      return {
        background: colors.white,
        color: colors.gray800,
      };
    }
  }, [type]);

  return (
    <Flex alignItems="center" width="100%" flexDirection="column">
      <Box
        css={{
          width: "100%",
          maxWidth: maxWidth || "480px",
          margin: 0,
          position: "fixed",
          bottom: bottom || 80,
          right: right || 10,
          zIndex: 100,
          display: "flex",
          justifyContent: "end",
          transform: transform,
        }}
      >
        <HStack
          as="button"
          onClick={() => {
            customEvent(event);

            overlay.open(({ isOpen, close: onClose }) => {
              return (
                <FileRequestModal
                  path={
                    router.pathname.includes("facilties") || router.pathname.includes("memori")
                      ? "facility-detail"
                      : "home"
                  }
                  isOpen={isOpen}
                  onClose={onClose}
                />
              );
            });
          }}
          css={css`
            // margin-right: 10px;
            background: ${typeToStyle.background};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px rgba(113, 120, 125, 0.4);
            border-radius: 26px;
          `}
          padding="4px 10px 4px 4px"
          alignItems="center"
          justifyContent="center"
          gap={8}
        >
          <Image
            src={getCdnImageSrc(`platform/home/match-request-version/circle-guidebook.png`)}
            alt="step-guide"
            width={size}
            height={size}
          />
          <Box className="subtitle_14_b" textAlign="left" color={typeToStyle.color}>
            장례비용 예상 견적표,
            <br />
            임종준비 체크리스트 받아보기
          </Box>
          <ColorIcon
            name="icon-arrow-right-circle-mono"
            size={36}
            color={typeToStyle.color}
            css={{ marginLeft: iconMarginLeft || "0px" }}
          />
        </HStack>
      </Box>
    </Flex>
  );
}

export default StepGuideFooter;
