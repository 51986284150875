import styled from "@emotion/styled";

export const GlobalContainer = styled.div`
  display: flex;
  height: calc(var(--vh) * 100);
  justify-content: center;
`;

export const GlobalWrapper = styled.div`
  position: relative;
  min-width: 320px;
  min-height: calc(var(--vh) * 100);
  background-color: var(--white);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0px !important;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
`;
