import { ComponentProps } from "react";
import { atom } from "recoil";
import Dialog from "../components/Dialog/Dialog";

export type DialogProps = Omit<ComponentProps<typeof Dialog>, "isOpen" | "onClose">;
export const dialogPropsState = atom<DialogProps>({
  key: "dialogPropsState",
  default: {
    type: 1,
    header: "",
    children: "",
    footerProps: {
      text: "",
      onClick: () => {},
    },
    closeOnOverlayClick: true,
  },
});
