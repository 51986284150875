import { RefObject, useEffect } from "react";

interface IntersectionObserverProps {
  root: any;
  target: RefObject<Element>;
  onIntersect: () => any;
  onNotIntersect?: () => any;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}

export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  onNotIntersect,
  threshold = 1.0,
  rootMargin = "10px",
  enabled = true,
}: IntersectionObserverProps) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]): void => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
          if (!entry.isIntersecting) {
            if (onNotIntersect) {
              onNotIntersect();
            }
          }
        });
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target, enabled, root, threshold, rootMargin, onIntersect]);
}
