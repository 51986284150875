import { Box } from "@chakra-ui/react";
import { ColorIcon, HStack, Spacing, VLine, VStack, call, colors } from "goi_common";
import { OverlayModal, OverlayModalFooterProps } from "goi_common";

export default function NotFacilitiesModalContent() {
  return (
    <VStack width="100%" gap={10}>
      <VStack width="100%" padding="40px 24px 20px">
        <VStack gap={12} padding="0 0 32px">
          <Box className="subtitle_21_b white">
            잠시만요!
            <br />이 번호는 <span style={{ color: "#E2452B" }}>장지 업체가 아닙니다.</span>
          </Box>
          <Spacing gap={16} />
          <Box className="subtitle_16_b gray200">
            장례를 준비하시는 고객님들께
            <br />
            최저가 비교, 합리적인 장례
            <br />
            엄선한 장례지도사 <br />
            장례 상담을 도와드리는
            <div className="orange650">상조 서비스 {"‘고이'"}입니다.</div>
          </Box>
          <VLine width="100%" height="1px" color={colors.gray700} margin="36px 0" />
          <Box textAlign="center" className="subtitle_16_b gray100">
            단순 문의, 교통편 문의,
            <br />
            고인/개인정보 문의는 <br />
            이 곳이 아니라 해당 업체 전화번호로
            <br />
            문의 부탁드립니다.
          </Box>
        </VStack>
      </VStack>
    </VStack>
  );
}

function NotFacilitiesModalContentFooter({
  onClose,
  callOnly = false,
  category = "",
  callTo = "1666-9784",
}: {
  onClose: () => void;
  callOnly?: boolean;
  category?: string;
  callTo?: string;
}) {
  const footerProps: OverlayModalFooterProps = {
    item: [
      {
        text: <>돌아가기</>,
        onClick: () => {
          onClose();
        },
        width: "30%",
        event: {
          newGtm: {
            path: "snackbar",
            trigger_and_target: "quick_mdl_ftr_lft-uv_review",
          },
        },
      },
      {
        text: (
          <HStack width="100%" alignItems="center" justifyContent="center" gap={8}>
            <ColorIcon name="icon-call-mono" color={colors.white} width={16} height={16} />
            <div>장례 상담/임종 접수</div>
          </HStack>
        ),
        onClick: () => {
          call(callTo);
        },
        event: {
          newGtm: {
            path: "snackbar",
            trigger_and_target: "quick_mdl_ftr_rght-actn_call",
          },
        },
      },
    ],
  };

  return <OverlayModal.Footer.TwinBtnType props={footerProps} />;
}

NotFacilitiesModalContent.footer = NotFacilitiesModalContentFooter;
