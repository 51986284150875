import { colors } from "./theme";

export const colorCssVariables: string[] = (function () {
  const colorVariables: string[] = [];
  Object.entries(colors).forEach(([key, value]) => {
    colorVariables.push(`--${key}: ${value}`);
  });

  return colorVariables;
})();
