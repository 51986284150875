import {
  KeyValue,
  CSSPixelValue,
  HStack,
  coerceCssPixelValue,
} from "goi_common";
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface TagsProps<V> {
  tags: KeyValue<V>[];
  value: V;
  onChange?: (tag: V) => void;
  padding?: CSSPixelValue;
  fluid?: boolean;
}
export function Tags<V>({
  tags,
  value,
  onChange,
  padding,
  fluid = true,
}: TagsProps<V>) {
  return (
    <HStack css={tagRootCss({ fluid })}>
      {padding && <Padding value={padding} />}
      <HStack
        gap={12}
        width={fluid ? "unset" : "100%"}
        style={{ flexWrap: fluid ? "nowrap" : "wrap" }}
      >
        {tags.map((tag) => (
          <Tag
            key={tag.key}
            selected={tag.value === value}
            onClick={() => {
              onChange?.(tag.value);
            }}
          >
            {tag.key}
          </Tag>
        ))}
      </HStack>
      {padding && <Padding value={padding} />}
    </HStack>
  );
}

const tagRootCss = (props: { fluid: boolean }) =>
  css({
    overflowX: props.fluid ? "auto" : "visible",

    ["::-webkit-scrollbar"]: {
      display: "none",
    },

    ["> div"]: {
      flexShrink: 0,
    },
  });

const Padding = styled.div<{ value?: CSSPixelValue }>`
  min-width: ${(props) => coerceCssPixelValue(props.value)};
`;
const Tag = styled.button<{ selected: boolean }>`
  padding: 7px 19px;
  border-radius: 30px;
  flex-shrink: 0;
  transition-property: border-color, background-color, color;
  transition-duration: 0.2s;

  ${(props) =>
    props.selected
      ? css`
          border: 1px solid transparent;
          background: var(--black);
          color: var(--gray50);
        `
      : css`
          border: 1px solid var(--gray100);
          background: var(--white);
          color: var(--gray800);
        `}
`;
