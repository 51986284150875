import { Select } from "@chakra-ui/react";
import { ColorIcon, colors } from "goi_common";
import React from "react";

interface DropdownProps {
  name: string;
  value: string;
  onChange: any;
  values: Array<any>;
}

export function GrayNonBorderDropdown({
  name,
  value,
  onChange,
  values,
}: DropdownProps) {
  return (
    <Select
      background={colors.gray50}
      color={value ? "" : colors.gray400}
      border="none"
      className="subtitle_14_sb"
      fontSize={14}
      borderRadius="0px"
      value={value}
      onChange={onChange}
      icon={
        <ColorIcon
          name="icon-arrow-right-small-mono"
          size={16}
          color={colors.gray600}
          css={{ transform: "rotateZ(90deg)" }}
        />
      }
    >
      {values.length > 0 ? (
        <>
          <option value={""}>{name}</option>
          {values.map((res: any) => {
            return (
              <option key={res.value} value={res.value}>
                {res.key}
              </option>
            );
          })}
        </>
      ) : (
        <option value={0}>--</option>
      )}
    </Select>
  );
}
