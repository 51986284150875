import { default as NextLink, LinkProps as NextLinkProps } from "next/link";
import React, { AnchorHTMLAttributes, PropsWithChildren } from "react";

type LinkProps = PropsWithChildren<NextLinkProps> &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">;

export const Link = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  children,
  ...rest
}: LinkProps) => {
  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
      {...rest}
    >
      {children}
    </NextLink>
  );
};
