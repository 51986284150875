import {
  VStack,
  HStack,
  Button,
  colors,
  GLOBAL_MAX_WIDTH,
  TwinButton,
  useResponsive,
} from "goi_common";

export function FixedResponsiveTwinButton({
  firstButton,
  secondButton,
}: {
  firstButton: { text: string; onClick: () => void };
  secondButton: { text: string; onClick: () => void };
}) {
  const { isBiggerThanLg } = useResponsive();

  return (
    <>
      {isBiggerThanLg ? (
        <VStack
          width="100%"
          background={colors.gray900}
          alignItems="center"
          justifyContent="center"
        >
          <HStack
            maxWidth={GLOBAL_MAX_WIDTH}
            width="100%"
            alignItems="center"
            justifyContent="flex-end"
            margin="0px 24px"
            gap={16}
          >
            <Button
              className="button_16_sb"
              style={{
                color: "white",
                background: colors.orange650,
                width: "240px",
                height: "50px",
                borderRadius: "5px",
              }}
              onClick={firstButton.onClick}
            >
              {firstButton.text}
            </Button>

            <Button
              className="button_16_sb"
              style={{
                color: "white",
                background: colors.orange650,
                width: "240px",
                height: "50px",
                borderRadius: "5px",
              }}
              onClick={secondButton.onClick}
            >
              {secondButton.text}
            </Button>
          </HStack>
        </VStack>
      ) : (
        <TwinButton
          height="60px"
          firstButton={{
            width: "30%",
            background: colors.gray900,
            text: firstButton.text,
            onClick: firstButton.onClick,
          }}
          secondButton={{
            background: colors.orange650,
            text: secondButton.text,
            onClick: secondButton.onClick,
          }}
        />
      )}
    </>
  );
}
