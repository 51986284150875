import { css } from "@emotion/react";

export const flexCentering = (
  direction: "row" | "column" | "row-reverse" | "column-reverse"
) => css`
  display: flex;
  flex-direction: ${direction};
  justify-content: center;
  align-items: center;
`;
