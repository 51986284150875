import styled from "@emotion/styled";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import React, { useRef, useState } from "react";
import { VStack } from "goi_common";

export function LazyLoadYoutube({
  src = "https://www.youtube.com/embed/PPhQUcQYFKU",
  headText,
}: {
  src?: string;
  headText?: string;
}) {
  const [visible, setVisible] = useState(false);
  const lazyLoadYoutubeRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    root: null,
    target: lazyLoadYoutubeRef,
    onIntersect: () => {
      setVisible(true);
    },
  });

  return (
    <>
      <YoutubeRefWrapper ref={lazyLoadYoutubeRef}></YoutubeRefWrapper>
      <VStack gap={24}>
        {headText && (
          <div className="new-head white" style={{ paddingLeft: "8px" }}>
            {headText}
          </div>
        )}

        {visible && (
          <IframeWrapper>
            <YoutubeIframe
              src={src}
              title="YouTube video player"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            />
          </IframeWrapper>
        )}
      </VStack>
    </>
  );
}

const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
`;
const YoutubeIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
`;
const YoutubeRefWrapper = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100px;
  left: 0;
  transform: translateY(-300px);
`;
