import styled from "@emotion/styled";
import React from "react";
import { HStack } from "./HStack";
import { VStack } from "./VStack";

interface ScrollProps {
  children: React.ReactNode;
  gap?: number;
  height?: string;
  style?: object;
  listRef?: any;
}

export function FlowScrollSlider({
  children,
  gap,
  height,
  style,
  listRef,
}: ScrollProps) {
  return (
    <NoScrollBar height={height} gap={gap} style={style} ref={listRef}>
      {children}
    </NoScrollBar>
  );
}

const NoScrollBar = styled(HStack)`
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface SliderWrapProps {
  children: React.ReactNode;
  padding?: string;
  maxWidth?: string;
  background?: string;
}

function ScrollSliderWrapper({
  children,
  padding,
  maxWidth,
  background,
}: SliderWrapProps) {
  return (
    <VStack
      width="100%"
      alignItems="center"
      background={background}
      padding={padding}
    >
      <VStack width="100%" maxWidth={maxWidth}>
        {children}
      </VStack>
    </VStack>
  );
}

interface ItemProps extends ScrollProps {
  onClick?: () => void;
  lastGap?: string;
  isLastComponent?: boolean;
  isFirstComponent?: boolean;
  id?: string;
}

function ScrollItemWrapper({
  children,
  onClick,
  gap,
  isLastComponent,
  isFirstComponent,
  lastGap,
  id,
}: ItemProps) {
  const isOnClickTrue = Boolean(onClick);

  return (
    <VStack
      gap={gap}
      onClick={onClick}
      margin={
        isLastComponent
          ? `0 ${lastGap} 0 0`
          : isFirstComponent
          ? `0 0 0 ${lastGap}`
          : "0"
      }
      style={isOnClickTrue ? { cursor: "pointer" } : {}}
      id={id}
    >
      {children}
    </VStack>
  );
}

FlowScrollSlider.ItemWrapper = ScrollItemWrapper;
FlowScrollSlider.SliderWrapper = ScrollSliderWrapper;
