import { useEffect, useState } from "react";

export function useDebounce<T>(input: T, delay = 500) {
  const [debounced, setDebounced] = useState(input);

  useEffect(() => {
    const timerId = setTimeout(() => setDebounced(input), delay);

    return () => clearTimeout(timerId);
  }, [delay, input]);

  return debounced;
}
