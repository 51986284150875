import styled from "@emotion/styled";

export const ListItem = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  white-space: pre-line;

  ::marker {
    content: "•";
  }

  :before {
    content: "";
    display: inline-block;
    width: 7px;
  }
`;
