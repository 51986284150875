import { css } from "@emotion/react";
import { ColorIcon, colors, HStack, useResponsive } from "goi_common";
import { ReactNode, useMemo, useState } from "react";
import useTablePagination from "../../hooks/useTablePagination";

function TablePagination({
  totalCount,
  page,
  setPage,
  limit = 20,
  maxPage,
}: {
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
  limit?: number;
  maxPage?: number;
}) {
  const { isBiggerThanLg: isTablet } = useResponsive();

  const maxShowPage = useMemo(() => {
    if (maxPage) return maxPage;
    if (isTablet) return 10;

    return 5;
  }, [isTablet]);

  const {
    baseNumber,
    currentPage,
    onClickPrevious,
    onClickNext,
    setCurrentPage,
    totalPages,
  } = useTablePagination({
    currentPage: page,
    setCurrentPage: setPage,
    totalCount: totalCount || 0,
    maxShowPage,
    limit,
  });
  const pageLength = totalPages < maxShowPage ? totalPages : maxShowPage;

  const isShowPreviousButton = totalPages >= maxShowPage && baseNumber > 0;
  const isShowNextButton = baseNumber * maxShowPage + pageLength < totalPages;

  return (
    <div
      css={css`
        display: flex;
        height: 24px;
      `}
    >
      {isShowPreviousButton && <Previous onClick={onClickPrevious} />}
      {Array(pageLength)
        .fill(1)
        .map((val, index) => {
          const page = baseNumber * maxShowPage + (index + 1);
          if (page <= totalPages) {
            return (
              <PageNumber
                key={`page_number_${index + 1}`}
                primary={currentPage === page}
                onClick={() => {
                  setCurrentPage(page);
                }}
              >
                {page}
              </PageNumber>
            );
          }
        })}
      {isShowNextButton && <Next onClick={onClickNext} />}
    </div>
  );
}
const PageNumber = ({
  children,
  primary,
  onClick,
}: {
  children: ReactNode;
  primary: boolean;
  onClick: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <span
      onClick={onClick}
      className={
        primary || isHovering
          ? "button2 orange650 font-700"
          : "body3_m gray600 font-500"
      }
      css={css`
        cursor: pointer;
        &:not(:last-of-type) {
          margin-right: 24px;
        }
      `}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      {children}
    </span>
  );
};
const Previous = ({ onClick }: { onClick: () => void }) => {
  return (
    <HStack
      onClick={onClick}
      css={css`
        cursor: pointer;
        margin-right: 24px;
      `}
      className="body3_m gray600"
      gap={4}
      alignItems="center"
    >
      <ColorIcon name="arrow_left_small" size={12} color={colors.gray600} />
      <span>이전</span>
    </HStack>
  );
};
const Next = ({ onClick }: { onClick: () => void }) => {
  return (
    <HStack
      onClick={onClick}
      css={css`
        cursor: pointer;
        margin-left: 24px;
      `}
      className="body3_m gray600"
      gap={4}
      alignItems="center"
    >
      <span>다음</span>
      <ColorIcon name="arrow_right_small" size={12} color={colors.gray600} />
    </HStack>
  );
};
export { TablePagination };
