import { HStack, ColorIcon, colors } from "goi_common";
import React from "react";

export const GrayNotice = ({ text, fill }: { text: string; fill?: string }) => {
  return (
    <HStack gap={8} background={fill || colors.gray50} padding="16px 24px">
      <ColorIcon name="exclamation_point" size={24} color={colors.gray400} />
      <div style={{ color: colors.gray500 }} className="body3_m">
        {text}
      </div>
    </HStack>
  );
};
