interface GoiLogoProps {
  color?: string;
  size?: number;
  onClick?: () => void;
}

function GoiLogo({ color = "var(--black)", size = 24, onClick }: GoiLogoProps) {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 36 36`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        style={onClick && { cursor: "pointer" }}
      >
        <path
          d="M21.741 11.1371H18.0707V14.8431H21.741H25.4118V3.72554V0.740061V0.0195312H7.05884V3.72554H21.741V11.1371Z"
          fill={color}
        />
        <path
          d="M21.5295 16.9607C19.924 16.9607 18.3545 17.4368 17.0196 18.3288C15.6847 19.2207 14.6442 20.4885 14.0298 21.9718C13.4154 23.4552 13.2546 25.0873 13.5678 26.662C13.8811 28.2367 14.6542 29.6831 15.7895 30.8184C16.9247 31.9537 18.3712 32.7268 19.9458 33.04C21.5205 33.3532 23.1527 33.1925 24.636 32.5781C26.1193 31.9637 27.3871 30.9232 28.2791 29.5883C29.1711 28.2533 29.6472 26.6839 29.6472 25.0783C29.6472 22.9254 28.7919 20.8606 27.2696 19.3383C25.7472 17.8159 23.6824 16.9607 21.5295 16.9607ZM21.5295 29.5392C20.6473 29.5389 19.785 29.277 19.0516 28.7866C18.3182 28.2963 17.7466 27.5995 17.4093 26.7843C17.0719 25.9691 16.9838 25.0722 17.1561 24.207C17.3284 23.3418 17.7534 22.5471 18.3774 21.9234C19.0013 21.2997 19.7962 20.875 20.6615 20.7031C21.5268 20.5311 22.4237 20.6196 23.2387 20.9574C24.0537 21.2951 24.7503 21.8669 25.2403 22.6005C25.7304 23.3342 25.9919 24.1966 25.9918 25.0788C25.9917 25.6647 25.8762 26.2448 25.6519 26.7861C25.4276 27.3273 25.0989 27.8191 24.6846 28.2333C24.2702 28.6475 23.7783 28.976 23.2369 29.2001C22.6956 29.4241 22.1154 29.5393 21.5295 29.5392Z"
          fill={color}
        />
        <path
          d="M32.6852 10.6079H29.647V14.321H32.1748C32.3036 14.321 32.4272 14.3779 32.5183 14.4791C32.6093 14.5804 32.6605 14.7177 32.6605 14.8608V35.3138H35.9999V14.2921C35.9999 13.8637 35.8468 13.4529 35.5743 13.1501L33.7127 11.081C33.4402 10.7781 33.0706 10.6079 32.6852 10.6079Z"
          fill={color}
        />
        <path
          d="M14.8235 14.6388V7.07837H11.1246V14.0608C11.1246 14.2061 11.0679 14.3454 10.9671 14.4481C10.8662 14.5507 10.7294 14.6084 10.5868 14.6084H0V18.3725H11.153C11.5798 18.3721 11.9891 18.1995 12.2913 17.8926L14.3522 15.7938C14.6533 15.4875 14.8228 15.0722 14.8235 14.6388Z"
          fill={color}
        />
      </svg>
    </>
  );
}

export { GoiLogo };
