import { useRouter } from "next/router";
import { useMemo } from "react";

export function useActivePathCheck() {
  const router = useRouter();

  const activePath = useMemo(() => {
    const path = router.asPath;

    if (path === "/") return "홈";
    if (path.startsWith("/match/request/")) return "장례 견적";
    if (path.startsWith("/guidebook/")) return "가이드북";
    if (path === "/review/") return "고객 후기";

    return "";
  }, [router]);

  return { activePath };
}
