import { useFeature } from "@hackler/react-sdk";
import { getSeoulTime } from "goi_common";
import { useMemo } from "react";

export const useOffDirectCall = () => {
  const isOffDirectCallFeature = useFeature(4);

  const seoulTime = getSeoulTime();
  const seoulDay = seoulTime.day();
  const seoulHour = seoulTime.hour();

  const isOffDirectCall = useMemo(() => {
    if (!isOffDirectCallFeature) return false;

    const 심야 = seoulHour >= 21 || seoulHour <= 7;
    const 평일_아침_솔로응대 =
      [1, 2, 3, 4, 5].includes(seoulDay) && seoulHour >= 7 && seoulHour <= 10;
    const 금요일_솔로응대 = [5].includes(seoulDay) && seoulHour >= 19;
    const 주말 = [6, 0].includes(seoulDay);

    if (금요일_솔로응대 || 주말) {
      return true;
    }

    if (심야 || 평일_아침_솔로응대) {
      return true;
    }

    return false;
  }, [seoulDay, seoulHour, isOffDirectCallFeature]);

  return isOffDirectCall;
};
