export enum Typography {
  "head2_b" = "head2_b",
  "caption0" = "caption0",
  "caption1" = "caption1",
  "caption2" = "caption2",
  "body1" = "body1",
  "body2" = "body2",
  "body3_r" = "body3_r",
  "body3_m" = "body3_m",
  "title" = "title",
  "subtitle1_b" = "subtitle1_b",
  "subtitle2_b" = "subtitle2_b",
  "subtitle2_sb" = "subtitle2_sb",
  "subtitle3" = "subtitle3",
  "button" = "button",
  "button2" = "button2",
  "subtitle_18_sb" = "subtitle_18_sb",
  "subtitle_21_b" = "subtitle_21_b",
  "subtitle_21_sb" = "subtitle_21_sb",
}
