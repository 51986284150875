import styled from "@emotion/styled";
import React, { CSSProperties, HTMLAttributes } from "react";
import { colors } from "goi_common";
import { Typography } from "../constants/typography";
import { css } from "@emotion/react";
import { CSSPixelValue } from "../types";
import { coerceCssPixelValue } from "../utils";

type BadgeType = "rounded" | "rounded-square";

interface BaseProps {
  type?: BadgeType;
  backgroundColor?: string;
  color?: string;
  typography?: Typography;
  position?: CSSProperties["position"];
  top?: CSSPixelValue;
  left?: CSSPixelValue;
  margin?: CSSPixelValue;
  padding?: CSSPixelValue;
}

type Props = BaseProps & Omit<HTMLAttributes<HTMLDivElement>, keyof BaseProps>;

const Badge = ({
  type = "rounded-square",
  backgroundColor = colors.gray50,
  color = colors.green500,
  typography = Typography.caption0,
  position,
  top,
  left,
  margin,
  padding,
  className,
  style,
  children,
  ...rest
}: Props) => {
  return (
    <BadgeRoot
      type={type}
      className={`${typography} ${className ?? ""}`}
      style={{
        ...style,
        position,
        top: coerceCssPixelValue(top),
        left: coerceCssPixelValue(left),
        backgroundColor,
        color,
        margin: coerceCssPixelValue(margin),
        padding: coerceCssPixelValue(padding),
      }}
      {...rest}
    >
      {children}
    </BadgeRoot>
  );
};

const BadgeRoot = styled.div<{ type: BadgeType }>`
  width: fit-content;
  height: fit-content;
  ${(props) => styleByType(props.type)};
`;

const styleByType = (type: BadgeType) => {
  switch (type) {
    case "rounded":
      return css`
        padding: 6px 16px;
        border-radius: 30px;
      `;
    case "rounded-square":
      return css`
        padding: 6px 12px;
        border-radius: 5px;
      `;
  }
};

export { Badge };
