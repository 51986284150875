import dayjs from "dayjs";

interface DaumSEO {
  title: string;
  description: string;
  dateString?: `${number}.${number}.${number} ${number}`;
}

export function DaumSEO({ title, description, dateString }: DaumSEO) {
  const getDaumDateTime = () => dayjs().format("YYYY.M.D H");
  /** Next.js Hydration Error 방지 목적 -> 서버에서 만든 시간(시, 분, 초)와 클라이언트의 시간이 다름 */
  const datetime = dateString ? dateString : getDaumDateTime() + ":10:10";

  return (
    <div style={{ display: "none" }}>
      <div className="daum-wm-title">{title}</div>
      <div className="daum-wm-content">{description}</div>
      <div className="daum-wm-datetime">{datetime}</div>
    </div>
  );
}
