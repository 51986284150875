const naverPageView = () => {
  if (!window.wcs_add) window.wcs_add = {};
  window.wcs_add["wa"] = "12920d4981c6a10";
  if (!window._nasa) window._nasa = {};
  if (window.wcs) {
    window.wcs.inflow();
    window.wcs_do(window._nasa);
  }
};
export default naverPageView;
