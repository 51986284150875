import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface SlidingMenuProps {
  items: string[];
  current: number;
  setCurrent: (current: number) => void;
}
function SlidingMenu({ items, current, setCurrent }: SlidingMenuProps) {
  const length = items.length;

  return (
    <SlidingMenuRoot current={current} length={length}>
      <SlidingMenuItems>
        {items.map((i, index) => (
          <SlidingMenuItem
            key={i}
            length={length}
            isActive={current === index}
            onClick={() => setCurrent(index)}
            className="body3_m gray700"
          >
            {i}
          </SlidingMenuItem>
        ))}
      </SlidingMenuItems>
    </SlidingMenuRoot>
  );
}

const SlidingMenuRoot = styled.div<{ current: number; length: number }>`
  padding: 0 0 0 18px;
  border-bottom: 1px solid var(--gray300);
`;

const SlidingMenuItems = styled.div`
  display: flex;
`;

const SlidingMenuItem = styled("h2")<{ length: number; isActive: boolean }>`
  cursor: pointer;
  height: 36x;
  width: fit-content;
  padding: 0px 0px 6px 0px;
  margin-right: 16px;
  ${({ isActive }) =>
    isActive
      ? css`
          font-weight: bold;
          color: var(--orange700);
          border-bottom: 4px solid var(--orange700);
        `
      : css`
          border-bottom: 4px solid transparent;
        `}
  transition: 0.2s;
`;

export { SlidingMenu };
