import { setCookie, getCookie } from "./cookie";

function getParameterByName(name: string, query: string | null = "") {
  query = query || window.location.search;
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(query);
  return results === null
    ? undefined
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const utm_keys = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];
const landingPage_key = "landing_page";
const referrer_key = "referrer";
const kakao_auth_referrer = [
  "https://kauth.kakao.com/",
  "https://accounts.kakao.com/",
  "https://logins.daum.net/",
];

const setPropertyCookie = (name: string, value: string) => {
  const three_months_in_sec = 7776000;
  const domain = "goifuneral.co.kr";
  setCookie(name, value, three_months_in_sec, domain);
};

export function initUserProperties() {
  const utms: any = {};
  utm_keys.forEach((key) => {
    utms[key] = getParameterByName(key);
  });

  Object.keys(utms).forEach(function (key) {
    if (utms[key]) {
      setPropertyCookie(key, utms[key] ?? "");
    }
  });

  let n_ad_group = getParameterByName("n_ad_group") || "";
  let n_query = getParameterByName("n_query") || "";

  if (n_ad_group === "grp-a001-01-000000030225533") {
    n_ad_group = "무빈소장";
  } else if (n_ad_group === "grp-a001-01-000000030164644") {
    n_ad_group = "가족장";
  } else if (n_ad_group === "grp-a001-01-000000030225530") {
    n_ad_group = "장례 비용";
  }

  let trial = 0;
  while (trial < 10 && n_query.includes("%")) {
    n_query = decodeURIComponent(n_query);
    trial++;
  }

  if (n_ad_group) {
    setPropertyCookie("n_ad_group", n_ad_group);
  }

  if (n_query) {
    setPropertyCookie("n_query", n_query);
  }

  const landingPage = document.location.href;
  if (!getCookie(landingPage_key) && !landingPage.includes("?code=")) {
    setPropertyCookie(landingPage_key, landingPage);
  }

  const referrer = document.referrer;
  if (referrer && !kakao_auth_referrer.includes(referrer)) {
    setPropertyCookie(referrer_key, referrer);
  }
}

export function getUserProperties() {
  const res: any = {};

  utm_keys.forEach((key) => {
    const value = getCookie(key);
    res[key] = value;
  });

  const n_ad_group = getCookie("n_ad_group");
  res["n_ad_group"] = n_ad_group;
  const n_query = getCookie("n_query");
  res["n_query"] = n_query;

  const landingPage = getCookie(landingPage_key);
  res[landingPage_key] = landingPage;

  const referrer = getCookie(referrer_key);
  res[referrer_key] = referrer;

  return res;
}
