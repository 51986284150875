import { Box, Button, Input, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import {
  Checkbox,
  ColorIcon,
  colors,
  customEvent,
  Field,
  getCookie,
  HStack,
  VStack,
} from "goi_common";
import { useCallback, useState } from "react";
import { useRouter } from "next/router";
import { Axios } from "@/utils/axios";

export default function FileRequestModal({
  isOpen,
  onClose,
  path = "parlor_detail",
}: {
  isOpen: boolean;
  onClose: () => void;
  path?: "parlor_detail" | "home" | "facility-detail" | "login" | "gdb_tip";
}) {
  const [type] = useState("번호");
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [memo, setMemo] = useState("");
  // const handleTypeChange = useCallback((type: string) => {
  //   setType(type);
  // }, []);
  const router = useRouter();

  const handleClick = useCallback(() => {
    customEvent({
      newGtm: {
        path: path as any,
        trigger_and_target: "file_request_mdl-try_submit",
      },
    });
    if (!checked) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }
    if (type === "이메일") {
      alert("현재 이메일 발송 시스템 점검중으로 번호입력만 가능합니다.");
    } else {
      if (!value) {
        alert("번호를 입력해주세요.");
        return;
      } else {
        if (!memo) {
          alert("장례 준비 상황을 알려주세요.");
          return;
        }
        if (/^010-\d{4}-\d{4}$/.test(value)) {
          Axios.post("/checklist_request/", {
            telephone: value,
            path: router.asPath,
            memo,
            referrer: getCookie("referrer"),
          })
            .then(() => {
              customEvent({
                newGtm: {
                  path: path as any,
                  trigger_and_target: "file_request_mdl-submit_success",
                  text: value,
                },
              });
              alert("신청이 완료되었습니다.");
              onClose();
            })
            .catch(() => {
              alert("번호를 확인해주세요.");
            });
        } else {
          alert("번호 형식을 확인해주세요. (010-****-****)");
          return;
        }
      }
    }
  }, [checked, value, type]);
  return (
    <Box height="100%" width="100%">
      <Modal
        isOpen={isOpen}
        onClose={() => {
          customEvent({
            newGtm: {
              path: path as any,
              trigger_and_target: "file_request_mdl-close",
            },
            triggerHackle: true,
          });
          onClose();
        }}
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isCentered={true}
        useInert={false}
      >
        <ModalOverlay width="100%" height="100%" />
        <ModalContent minWidth="300px" backgroundColor={colors.white} backgroundSize="cover">
          <ModalBody padding="20px 24px 44px">
            <VStack width="100%" gap={10}>
              <HStack width="100%" justifyContent="end" alignItems="center">
                <HStack width="100%" alignItems="center" gap={8}>
                  <ColorIcon name="list" color={colors.gray900} width={16} height={16} />
                  <div className="subtitle_21_b gray900">체크리스트 받기</div>
                </HStack>
                <div
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path: path as any,
                        trigger_and_target: "file_request_mdl-close",
                      },
                      triggerHackle: true,
                    });
                    onClose();
                  }}
                >
                  <ColorIcon name="close" width={28} height={28} color={colors.black} />
                </div>
              </HStack>
              <VStack width="100%" gap={24}>
                <VStack gap={12} width="100%" className="body_16_m gray800">
                  <Box className="caption_12_b">
                    장례 비용 예상 견적표, 임종 준비 체크리스트를 받아볼 수 있습니다.
                  </Box>
                </VStack>
                {/* <HStack width="100%" gap={12}>
                  <Button
                    width="50%"
                    onClick={() => {
                      customEvent({
                        newGtm: {
                          path: path as any,
                          trigger_and_target: "file_request_mdl-email",
                        },
                      });
                      handleTypeChange("이메일");
                    }}
                    border={`1px solid ${type === "이메일" ? colors.black : "none"}`}
                  >
                    이메일로 받기
                  </Button>
                  <Button
                    width="50%"
                    onClick={() => {
                      customEvent({
                        newGtm: {
                          path: path as any,
                          trigger_and_target: "file_request_mdl-number",
                        },
                      });
                      handleTypeChange("번호");
                    }}
                    border={`1px solid ${type === "번호" ? colors.black : "none"}`}
                  >
                    번호로 받기
                  </Button>
                </HStack> */}
                <Input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  border={`1px solid ${colors.gray400}`}
                  width="100%"
                  placeholder={type === "이메일" ? "이메일을 입력하세요" : "010-****-****"}
                />
                <Box>
                  <Box className="gray700 subtitle_14_b" pb="12px">
                    상황을 알려주세요(맞춤형 안내)
                  </Box>
                  <Field.Radio
                    title=""
                    items={["미리 준비하려고 해요", "임종을 앞두거나 임종하신 상태입니다."]}
                    value={memo}
                    onChange={(e) => {
                      customEvent({
                        newGtm: {
                          path: "match_request",
                          trigger_and_target: "recommendation_memo-option",
                          text: e,
                        },
                      });
                      setMemo(e);
                    }}
                    radioWidth="100%"
                    className="body_14_r"
                    flexDirection="column"
                    alignItem="center"
                  />
                </Box>

                <HStack alignItems="center" justifyContent="space-between">
                  <Checkbox
                    index={2}
                    label="개인정보 수집, 이용, 제공 동의"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(!checked);
                    }}
                  />
                  <a
                    className="caption_11_b"
                    href="https://goifuneral.notion.site/146a79013e6c40a9b4731dd07a8ad99d"
                    target="_blank"
                  >
                    보기
                  </a>
                </HStack>
                <Button
                  width="100%"
                  backgroundColor={colors.orange650}
                  color={colors.white}
                  onClick={handleClick}
                >
                  자료 받아보기
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
