import { Button, colors, HStack } from "goi_common";
import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
export function FixedResponsiveBackgroundButton({
  buttons,
}: {
  buttons: Array<{
    text: ReactNode;
    onClick: () => void;
    backgroundColor?: string;
  }>;
}) {
  // const { isBiggerThanLg } = useResponsive();

  return (
    <Flex alignItems="center" width="100%" flexDirection="column">
      <HStack
        height={"68px"}
        background={colors.white}
        css={{
          width: "100%",
          maxWidth: "480px",
          margin: 0,
          height: "60px",
          position: "fixed",
          bottom: 0,
          zIndex: 5,
        }}
      >
        <HStack width="100%" height="100%" padding={"4px 20px 12px"} gap={8}>
          <Button
            style={{
              color: "white",
              width: "100%",
              height: "100%",
              backgroundColor: buttons[0].backgroundColor || colors.gray900,
              borderRadius: "5px",
            }}
            onClick={buttons[0].onClick}
            className="button"
          >
            {buttons[0].text}
          </Button>
        </HStack>
      </HStack>
    </Flex>
  );
}
