export const cooKeys = {
  visited_feature: "visited-feature",
  goi_member_id: "goi-member-id",
  goi_action: "goi-action",
  quotation_request: "quotation-request",
  quotation_request_CRM_tried: "quotationRequestCRMTried",
  quotation_request_facility: "facility-quotation-request",
  estimate_key: "estimate_key",
  block_banner: "block_banner",
  kakao_redirect: "kakao-redirect",
  kakao_auto_login_handled: "isKakaoAutoLoginHandled",
  previous: "previous",
  event_from: "event-from",
  first_visit_date: "first_visit_date",
  first_visit_parlor: "first_visit_parlor",
  goi_admin_refresh_token:
    process.env.NEXT_PUBLIC_IS_PRODUCTION === "true"
      ? "goi_admin_refresh_token"
      : "goi_admin_dev_refresh_token",
};
